import { Box, Button, Chip, CircularProgress, Collapse, Grid, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    useDeletePromotionalCodeMutation,
    useGetPromotionalCodeQuery,
    useUpdatePromotionalCodeMutation,
} from '../../../services/PromotionalCodes';
import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import NestedResourceNavigationLayout from '../../../components/Layouts/NestedResourceNavigationLayout/NestedResourceNavigationLayout';
import Axis from '../../../components/Layouts/Axis/Axis';
import SmallLabel from '../../../components/Typography/SmallLabel/SmallLabel';
import ExpandButton from '../../../components/Buttons/ExpandButton/ExpandButton';
import { formatDateToString } from '../../../utils/date';
import PromptModal from '../../../components/Modals/PromptModal/PromptModal';
import PromotionalCodeModal from '../../../components/Modals/PromotionalCodeModal/PromotionalCodeModal';
import { PromotionalCodeDTO } from '../../../utils/types';

type Props = {
    promoCodeId: string;
    activeStoreLocation: string;
};

const PromotionalCode = ({ promoCodeId, activeStoreLocation }: Props) => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const navigate = useNavigate();
    const {
        data: promoCode,
        isLoading,
        isSuccess,
    } = useGetPromotionalCodeQuery({ storeName: activeStoreLocation, promoCodeId });
    const [showPromoCodeInfo, setShowPromoCodeInfo] = useState(false);

    const [updatePromotionalCode, { isLoading: isUpdating, isSuccess: updateSuccess, data: updatedData }] =
        useUpdatePromotionalCodeMutation();

    const [deletePromotionalCode] = useDeletePromotionalCodeMutation();

    const toggleDeleteModal = () => setShowDeleteModal(!showDeleteModal);

    const toggleUpdateModal = () => setShowUpdateModal(!showUpdateModal);

    useEffect(() => {
        if (updateSuccess && updatedData) {
            setShowUpdateModal(false);
        }
    }, [updateSuccess, updatedData]);

    if (isLoading || !isSuccess || !promoCode) return <SubtleSpinner />;

    const handleDeletePromoCode = () => {
        deletePromotionalCode({ storeName: activeStoreLocation, promoCodeId: promoCode.id });
        toggleDeleteModal();
        navigate('../', { replace: true });
    };

    const handleActivatePromoCode = () => {
        const obj = Object.assign({}, promoCode, { isActive: !promoCode.isActive });
        if (!promoCode.startDate) delete obj.startDate;
        if (!promoCode.endDate) delete obj.endDate;
        updatePromotionalCode({ storeName: activeStoreLocation, data: obj });
    };

    const handleUpdatePromoCode = (data: PromotionalCodeDTO) => {
        const obj = Object.assign({}, data, { isActive: !promoCode.isActive });
        if (!data.startDate) delete obj.startDate;
        if (!data.endDate) delete obj.endDate;
        updatePromotionalCode({ storeName: activeStoreLocation, data });
    };

    const startDate = promoCode.startDate ? (
        <Typography>{formatDateToString(promoCode.startDate, 'EEEE do MMMM yyyy', true)}</Typography>
    ) : (
        <Typography sx={{ fontStyle: 'italic' }}>ingen dato</Typography>
    );
    const endDate = promoCode.endDate ? (
        <Typography>{formatDateToString(promoCode.endDate, 'EEEE do MMMM yyyy', true)}</Typography>
    ) : (
        <Typography sx={{ fontStyle: 'italic' }}>ingen dato</Typography>
    );

    return (
        <NestedResourceNavigationLayout currentPathname={promoCode.name} parentPath='Kampanjekoder'>
            <>
                <Box
                    mb={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: { sm: 'row', xs: 'column' },
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: { sm: 0, xs: 2 } }}>
                        <Typography sx={{ textTransform: 'capitalize' }} variant='h2'>
                            {promoCode.name}
                        </Typography>
                        <ExpandButton
                            isExpanded={showPromoCodeInfo}
                            handleClick={() => setShowPromoCodeInfo(!showPromoCodeInfo)}
                        />
                        <Chip
                            sx={{ ml: 2 }}
                            label={promoCode.isActive ? 'Aktiv' : 'Inaktiv'}
                            color={promoCode.isActive ? 'success' : 'error'}
                        />
                    </Box>
                    <Button
                        disabled={isUpdating}
                        startIcon={isUpdating ? <CircularProgress /> : undefined}
                        variant='outlined'
                        color={promoCode.isActive ? 'error' : 'success'}
                        onClick={handleActivatePromoCode}
                    >
                        {promoCode.isActive ? 'Deaktiver koden' : 'Aktiver koden'}
                    </Button>
                </Box>
                <Collapse in={showPromoCodeInfo}>
                    <Box sx={{ mb: 4 }}>
                        <Axis isResponsive gap={3}>
                            <Box>
                                <SmallLabel text={'Kampanjekode ID'} />
                                <Typography>{promoCode.id}</Typography>
                            </Box>
                            <Box>
                                <Button variant='contained' fullWidth color='error' onClick={toggleDeleteModal}>
                                    Slett kampanjekode
                                </Button>
                            </Box>
                        </Axis>
                    </Box>
                </Collapse>
                <Box mb={3}>
                    <Grid container wrap='wrap' gap={2}>
                        <Grid item>
                            <Box>
                                <SmallLabel text={'Beskrivelse'} />
                                <Typography>{promoCode.description ?? <i>ikke angitt</i>}</Typography>
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box>
                                <SmallLabel text={'Feilmelding som vises til kunder'} />
                                <Typography>{promoCode.customErrorMessage ?? <i>ikke angitt</i>}</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container wrap='wrap' gap={2} sx={{ mt: 4 }}>
                        <Grid item>
                            <Box>
                                <SmallLabel text={'Start-dato'} />
                                {startDate}
                            </Box>
                        </Grid>
                        <Grid item>
                            <Box>
                                <SmallLabel text={'Slutt-dato'} />
                                {endDate}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
                <Box>
                    <Button variant='outlined' onClick={toggleUpdateModal}>
                        Endre kampanjekode
                    </Button>
                </Box>
                <PromotionalCodeModal
                    openModal={showUpdateModal}
                    toggleModal={toggleUpdateModal}
                    initialValues={promoCode}
                    onSubmit={handleUpdatePromoCode}
                    isLoading={isUpdating && !updateSuccess}
                />
                <PromptModal
                    openModal={showDeleteModal}
                    toggleModal={toggleDeleteModal}
                    maxWidth='sm'
                    title='Slette kampanjekode?'
                    description='Kampanjekoden blir slettet for godt.'
                    leftButton={<Button onClick={toggleDeleteModal}>Avbryt</Button>}
                    rightButton={
                        <Button onClick={handleDeletePromoCode} variant='contained' color='error'>
                            Slett kampanjekoden
                        </Button>
                    }
                />
            </>
        </NestedResourceNavigationLayout>
    );
};

export default PromotionalCode;
