import createCache from '@emotion/cache';
import { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { ThemeProvider } from '@mui/material';
import { CacheProvider } from '@emotion/react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { render } from 'react-dom';

import AuthContainer from './containers/App/AuthContainer/AuthContainer';
import SubtleSpinner from './components/Indicators/SubtleSpinner/SubtleSpinner';
import MainConversation from './components/Extras/Conversation/MainConversation';
import IndexRouteMessage from './components/Layouts/IndexRouteMessage/IndexRouteMessage';
import InventorySearch from './views/Inventory/InventorySearch/InventorySearch';
import PlaceInventoryItem from './views/Inventory/PlaceInventoryItem/PlaceInventoryItem';
import ErrorMessageContainer from './containers/App/ErrorMessageContainer/ErrorMessageContainer';
import firebase from './configs/firebaseConfig';
import store from './state/store';
import { baseTheme } from './styles/theme';
import './styles/global.css';
import InventoryLocations from './views/Inventory/InventoryLocations/InventoryLocations';
import InventoryLocationContainer from './containers/PageSpecific/Inventory/InventoryLocationContainer/InventoryLocationContainer';
import { Flags } from './contexts/FeatureFlagsContext';
import AttributesContainer from './containers/PageSpecific/Inventory/AttributesContainer/AttributesContainer';
import ArticleAttributeContainer from './containers/PageSpecific/Inventory/ArticleAttributeContainer/ArticleAttributeContainer';
import ResetPassword from './views/ResetPassword';
import BookingCalendar from './views/Bookings/Calendar/BookingCalendar';
import BikeBoxes from './views/Bookings/BikeBoxes/BikeBoxes';
import BikeBoxContainer from './containers/PageSpecific/Bookings/BikeBoxes/BikeBoxContainer/BikeBoxContainer';
import PromotionalCodes from './views/Bookings/PromotionalCodes/PromotionalCodes';
import PromotionalCodeContainer from './containers/PageSpecific/Bookings/PromotionalCodes/PromotionalCodeContainer/PromotionalCodeContainer';

const rrfProps = {
    firebase,
    config: {},
    dispatch: store.dispatch,
    createFirestoreInstance,
};

const Pages = {
    Orders: lazy(() => import('./views/Orders')),
    Critical: lazy(() => import('./views/Critical')),
    Bookings: lazy(() => import('./views/Bookings/Bookings')),
    Conversations: lazy(() => import('./views/Conversations')),
    Statistics: lazy(() => import('./views/Statistics')),
    Login: lazy(() => import('./views/Login')),
    Inventory: lazy(() => import('./views/Inventory/Inventory')),
};

const spinner = <SubtleSpinner style={{ marginTop: 50 }} />;

const Index = () => {
    return (
        <CacheProvider value={createCache({ key: 'mui', prepend: true })}>
            <ThemeProvider theme={baseTheme}>
                <BrowserRouter>
                    <Routes>
                        <Route path='/' element={<p>no route match</p>} />
                        <Route
                            index
                            element={
                                <Suspense fallback={spinner}>
                                    <AuthContainer>
                                        <Flags
                                            allowedFlags={['orderSystem', 'bookingSystem']}
                                            redirectOnNoFeatureFlag
                                            exactFlags={false}
                                        >
                                            <Pages.Orders />
                                        </Flags>
                                    </AuthContainer>
                                </Suspense>
                            }
                        />
                        <Route
                            path='/oppfolging'
                            element={
                                <Suspense fallback={spinner}>
                                    <AuthContainer>
                                        <Flags allowedFlags={['orderSystem']} redirectOnNoFeatureFlag>
                                            <Pages.Critical />
                                        </Flags>
                                    </AuthContainer>
                                </Suspense>
                            }
                        />
                        <Route
                            path='/bookinger'
                            element={
                                <Suspense fallback={spinner}>
                                    <AuthContainer>
                                        <Flags allowedFlags={['bookingSystem']} redirectOnNoFeatureFlag>
                                            <Pages.Bookings />
                                        </Flags>
                                    </AuthContainer>
                                </Suspense>
                            }
                        >
                            <Route path='kalender' element={<BookingCalendar />} />
                            <Route path='sykkelbokser'>
                                <Route
                                    index
                                    element={
                                        <Flags allowedFlags={['bikeBoxSystem']} redirectOnNoFeatureFlag>
                                            <BikeBoxes />
                                        </Flags>
                                    }
                                />
                                <Route
                                    path=':bikeBoxId'
                                    element={
                                        <Flags allowedFlags={['bikeBoxSystem']} redirectOnNoFeatureFlag>
                                            <BikeBoxContainer />
                                        </Flags>
                                    }
                                />
                            </Route>
                            <Route path='kampanjekoder'>
                                <Route index element={<PromotionalCodes />} />
                                <Route
                                    path=':promotionalCodeId'
                                    element={
                                        <Flags allowedFlags={['bookingSystem']} redirectOnNoFeatureFlag>
                                            <PromotionalCodeContainer />
                                        </Flags>
                                    }
                                />
                            </Route>
                        </Route>
                        <Route
                            path='/meldinger'
                            element={
                                <Suspense fallback={spinner}>
                                    <AuthContainer>
                                        <Flags allowedFlags={['orderSystem', 'messageSystem']} redirectOnNoFeatureFlag>
                                            <Pages.Conversations />
                                        </Flags>
                                    </AuthContainer>
                                </Suspense>
                            }
                        >
                            <Route index element={<IndexRouteMessage message='Velg en samtale' />} />
                            <Route path=':customerId' element={<MainConversation />} />
                        </Route>
                        <Route
                            path='/statistikk'
                            element={
                                <Suspense fallback={spinner}>
                                    <AuthContainer>
                                        <Flags allowedFlags={['orderSystem']} redirectOnNoFeatureFlag>
                                            <Pages.Statistics />
                                        </Flags>
                                    </AuthContainer>
                                </Suspense>
                            }
                        />
                        <Route
                            path='/varelager'
                            element={
                                <Suspense fallback={spinner}>
                                    <AuthContainer>
                                        <Flags allowedFlags={['inventorySystem']} redirectOnNoFeatureFlag>
                                            <Pages.Inventory />
                                        </Flags>
                                    </AuthContainer>
                                </Suspense>
                            }
                        >
                            <Route path='sok' element={<InventorySearch />} />
                            <Route path='plasser-vare' element={<PlaceInventoryItem />} />
                            <Route path='lokasjoner'>
                                <Route index element={<InventoryLocations />} />
                                <Route path=':locationId' element={<InventoryLocationContainer />} />
                            </Route>
                            <Route path='attributter'>
                                <Route index element={<AttributesContainer />} />
                                <Route path=':attributeId' element={<ArticleAttributeContainer />} />
                            </Route>
                        </Route>
                        <Route
                            path='/logg-inn'
                            element={
                                <Suspense fallback={spinner}>
                                    <Pages.Login />
                                </Suspense>
                            }
                        />
                        <Route path='/reset-password' element={<ResetPassword />} />
                    </Routes>
                </BrowserRouter>
                <ErrorMessageContainer />
            </ThemeProvider>
        </CacheProvider>
    );
};

const root = document.getElementById('root');
render(
    <ReactReduxFirebaseProvider {...rrfProps}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Provider store={store}>
                <Index />
            </Provider>
        </LocalizationProvider>
    </ReactReduxFirebaseProvider>,
    root
);
