import { Box, Breadcrumbs, Typography } from '@mui/material';
import { ArrowLeft } from 'react-feather';
import { Link } from 'react-router-dom';

import CircularButton from '../../Buttons/CircularButton/CircularButton';

export type Props = {
    currentPathname: string;
    parentPath: string;
};

const NestedBreadcrumbs = ({ currentPathname, parentPath }: Props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Link to='..' style={{ textDecoration: 'none' }}>
                <CircularButton handleClick={() => {}}>
                    <ArrowLeft />
                </CircularButton>
            </Link>
            <Breadcrumbs aria-label='breadcrumb'>
                <Link to='..' style={{ textDecoration: 'none' }}>
                    <Typography sx={{ color: 'neutrals.600' }} variant='body2'>
                        {parentPath}
                    </Typography>
                </Link>
                <Typography sx={{ color: 'neutrals.800', fontWeight: 500, textTransform: 'capitalize' }}>
                    {currentPathname}
                </Typography>
            </Breadcrumbs>
        </Box>
    );
};

export default NestedBreadcrumbs;
