import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useSelector } from '../../../../state/hooks';
import InventoryLocation from '../../../../views/Inventory/InventoryLocations/InventoryLocation/InventoryLocation';
import { createPlacements, getLocations, getProductLocations } from '../../../../state/ducks/inventory/Locations';
// import { useDeleteProductLocationMutation } from '../../../../services/ProductLocations';

const InventoryLocationContainer = () => {
    const { locationId } = useParams();
    const dispatch = useDispatch();
    const { locations, creatingPlacements } = useSelector(state => state.inventory);
    // const [deleteProductLocation] = useDeleteProductLocationMutation();

    const currentLocation = locations.find(l => l.inventoryLocationId.toString() === locationId);

    useEffect(() => {
        if (!currentLocation) {
            dispatch(getLocations());
        }
        if (currentLocation) {
            dispatch(getProductLocations(currentLocation.inventoryLocationId, currentLocation.locationType));
        }
    }, [currentLocation?.inventoryLocationId, dispatch]);

    const handleSubmitPlacements = (placements: string[], callbackFn: VoidFunction) => {
        if (currentLocation)
            dispatch(
                createPlacements(
                    placements,
                    currentLocation.inventoryLocationId,
                    currentLocation.locationType,
                    callbackFn
                )
            );
    };

    // const handleClearPlacement = (
    //     placements: { articlePlacement: ArticlePlacement; count: number }[],
    //     callbackFn?: VoidFunction
    // ) => {
    //     if (currentLocation) {
    //         const placement = placements[0].articlePlacement;
    //         deleteProductLocation({
    //             locationId: currentLocation.inventoryLocationId,
    //             articleId: placement.articleId ?? undefined,
    //             placementId: placement.placementId ?? undefined,
    //             sizeColorId: placement.sizeColorId ?? undefined,
    //             count: 1,
    //         });
    //     }
    // };

    return (
        <InventoryLocation
            currentLocation={currentLocation}
            creatingPlacements={creatingPlacements}
            handleSubmitPlacements={handleSubmitPlacements}
        />
    );
};

export default InventoryLocationContainer;
