import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { createMakeStyles } from 'tss-react';
import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux';
import { isEmpty, isLoaded, useFirestoreConnect } from 'react-redux-firebase';

import Conversation from '../models/Conversation';
import PhoneNumber from '../models/PhoneNumber';
import { Notification, WeekdayCapacity, FeatureFlags, ConfigValues, BookingConfig } from '../utils/types';
import { RootState } from './reduxTypes';
import { matchPath, PathMatch, useLocation } from 'react-router-dom';

/**
 * Custom typed useSelector hook
 */
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

/**
 * Custom typed makeStyles for use with our theme
 */
export const { makeStyles } = createMakeStyles({ useTheme });

/**
 * Notification react hook.
 * Gets the messageControl doc from firebase in the shape of an array of Notifications
 *
 * @returns notification of type Notification
 */
export function useNotifications(): Notification[] {
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const rawNotifications: { [phoneNumbers: string]: string[] } = useSelector(
        state => state.firestore.data.messageControl
    );

    useFirestoreConnect({
        collection: 'messageControl',
        doc: 'newMessages',
        storeAs: 'messageControl',
    });

    useEffect(() => {
        if (isLoaded(rawNotifications)) {
            if (isEmpty(rawNotifications)) setNotifications([]);
            else {
                setNotifications(
                    Object.keys(rawNotifications).map(num => ({
                        phoneNumber: new PhoneNumber(num),
                        messages: rawNotifications[num],
                    }))
                );
            }
        }
    }, [rawNotifications]);

    return notifications;
}

/**
 * Flagged messages hook
 * Returns the number of flagged messages in firestore at this moment
 *
 * @returns number of flagged messages
 */
export function useFlaggedMessages(): number {
    const flaggedMessages = useSelector(state => state.firestore.data.flaggedMessages);

    useFirestoreConnect({
        collection: 'messageControl',
        doc: 'flaggedMessages',
        storeAs: 'flaggedMessages',
    });

    return flaggedMessages?.count ?? 0;
}

/**
 * Conversation react hook.
 * Gets the active conversation doc from firebase as a conversation object
 *
 * @parem storeLocationName - the name of the response document in the store. THIS MUST UNIQUE
 * @param customerId This is the customer number, which uniquely identofies the conversation
 * @returns The active Conversation if one exists, else null
 */
export function useActiveConversation(
    storeLocationName: string,
    customerNumber: string | null | undefined
): Conversation | null {
    const [conversation, setConversation] = useState<Conversation | null>(null);
    // TODO create a interface/type for the conversation document
    const conversationDoc: any = useSelector(state => state.firestore.data[storeLocationName]);

    useFirestoreConnect(
        customerNumber
            ? [
                  {
                      collection: 'customers',
                      doc: customerNumber,
                      storeAs: storeLocationName,
                  },
              ]
            : []
    );

    useEffect(() => {
        if (isLoaded(conversationDoc) && !isEmpty(conversationDoc)) {
            setConversation(
                // Only set the active conversation its id matches customerNumber
                conversationDoc?.customerNumber === customerNumber ? new Conversation(conversationDoc) : null
            );
        }
    }, [conversationDoc, setConversation, customerNumber]);

    return conversation;
}

/**
 * React router hook.
 * Used to match the current location to an array of possible matches.
 * Source: https://mui.com/guides/routing/#tabs
 *
 * @param patterns An array of strings representing possible route matches
 * @returns A match object, or null if no matches.
 */
export function useRouteMatch(patterns: readonly string[]): PathMatch<string> | null {
    const { pathname } = useLocation();

    for (const pattern of patterns) {
        const possibleMatch = matchPath({ path: pattern, caseSensitive: false, end: false }, pathname);
        if (possibleMatch !== null) {
            return possibleMatch;
        }
    }

    return null;
}

/**
 * Booking capacity hook.
 * Gets the weekday capacity, as well as the special capacities
 * @param storeName The store location to fetch capacity from.
 * @returns An object containing the weekday capacity in a 2D array (nonPri and prioritized), and Special capacity
 */
export function useBookingCapacity(storeName: string): {
    weekdayCapacity: WeekdayCapacity;
    calculatedWeekdayCapacities: WeekdayCapacity[];
    prioritizedCapacityFraction: number | null;
} {
    const bookingCapacityDocument = useSelector(state => state.firestore.data['bookingCapacityDocument']);
    const [weekdayCapacity, setWeekdayCapacity] = useState<WeekdayCapacity>([]);
    const [calculatedWeekdayCapacities, setCalculatedWeekdayCapacities] = useState<WeekdayCapacity[]>([]);
    const [prioritizedCapacityFraction, setPrioritizedCapacityFraction] = useState<number | null>(null);

    useFirestoreConnect([
        {
            collection: `bookings`,
            doc: storeName,
            storeAs: 'bookingCapacityDocument',
        },
    ]);

    useEffect(() => {
        if (isLoaded(bookingCapacityDocument) && !isEmpty(bookingCapacityDocument)) {
            const prioritizedCapacityFraction: number = bookingCapacityDocument.prioritizedCapacityFraction;
            setPrioritizedCapacityFraction(prioritizedCapacityFraction);

            const result: WeekdayCapacity[] = [[], []];

            bookingCapacityDocument.weekdayCapacity.forEach((weekdayCap: number) => {
                const prioritizedCap = Math.round(weekdayCap);
                const nonPrioritizedCap = Math.round(weekdayCap * (1 - prioritizedCapacityFraction));

                result[0].push(nonPrioritizedCap);
                result[1].push(prioritizedCap);
            });

            const nonPrioritizedWeekdayCapacity: WeekdayCapacity = result[0];
            const prioritizedWeekdayCapacity: WeekdayCapacity = result[1];

            setCalculatedWeekdayCapacities([nonPrioritizedWeekdayCapacity, prioritizedWeekdayCapacity]);
            setWeekdayCapacity(bookingCapacityDocument.weekdayCapacity);
        }
    }, [bookingCapacityDocument]);

    return {
        weekdayCapacity,
        calculatedWeekdayCapacities,
        prioritizedCapacityFraction: prioritizedCapacityFraction,
    };
}
/**
 * Feature flags hook.
 * @returns Feature flags objects
 */
export function useFeatureFlags() {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlags>();
    const featureFlagsDoc: any = useSelector(state => state.firestore.data['featureFlags']);

    useFirestoreConnect(['featureFlags']);

    useEffect(() => {
        if (isLoaded(featureFlagsDoc) && !isEmpty(featureFlagsDoc)) {
            setFeatureFlags(featureFlagsDoc);
        }
    }, [featureFlagsDoc]);

    return featureFlags;
}

export function useActiveFeatureFlags() {
    const featureFlags = useFeatureFlags();
    if (featureFlags) {
        return Object.keys(featureFlags).filter(flagId => {
            const feature = featureFlags[flagId];
            return feature.isActive;
        });
    }
}

export function useConfigValues() {
    const [configValues, setConfigValues] = useState<ConfigValues | null>(null);
    const configValuesDoc: any = useSelector(state => state.firestore.data['configValues']);

    useFirestoreConnect(['configValues']);

    useEffect(() => {
        if (isLoaded(configValuesDoc) && !isEmpty(configValuesDoc)) {
            setConfigValues(configValuesDoc);
        }
    }, [configValuesDoc]);

    return configValues;
}

export function useBookingConfig() {
    const [configValues, setConfigValues] = useState<BookingConfig | null>(null);
    const publicValuesDoc: any = useSelector(state => state.firestore.data['public']);
    useFirestoreConnect(['public']);

    useEffect(() => {
        if (isLoaded(publicValuesDoc) && !isEmpty(publicValuesDoc)) {
            const configValues: BookingConfig = {
                ...publicValuesDoc.bookingInfo,
            };
            setConfigValues(configValues);
        }
    }, [publicValuesDoc]);

    return configValues;
}
