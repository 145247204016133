import { Box, Typography } from '@mui/material';

import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import BikeBoxesContainer from '../../../containers/PageSpecific/Bookings/BikeBoxes/BikeBoxesContainer/BikeBoxesContainer';
import { useSelector } from '../../../state/hooks';

const BikeBoxes = () => {
    const activeStoreLocation = useSelector(state => state.system.activeStoreLocation);

    return (
        <Box sx={{ px: { md: 5, xs: 4 } }}>
            <Typography variant='h2'>Sykkelbokser</Typography>
            {activeStoreLocation !== null ? (
                <BikeBoxesContainer activeStoreLocation={activeStoreLocation} />
            ) : (
                <SubtleSpinner />
            )}
        </Box>
    );
};

export default BikeBoxes;
