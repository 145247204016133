import { TableHead, TableRow, TableCell, TableBody, Table } from '@mui/material';
import { MoreVertical } from 'react-feather';

import { formatDateToString } from '../../../utils/date';
import { BikeBoxReservation } from '../../../utils/types';
import TableCellMenu from '../TableCellMenu/TableCellMenu';
import { capitalizeText } from '../../../utils/format';

type Props = {
    reservations: BikeBoxReservation[];
    handleDeleteReservation: (reservationId: number) => void;
};

const BikeBoxReservationsTable = ({ reservations, handleDeleteReservation }: Props) => {
    const sortedReservations = [...reservations].sort((a, b) => {
        return new Date(a.fromDate).getTime() - new Date(b.fromDate).getTime();
    });
    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Kundenavn</TableCell>
                    <TableCell>Mobilnummer</TableCell>
                    <TableCell>Fra-dato</TableCell>
                    <TableCell>Til-dato</TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {sortedReservations.map(reservation => {
                    return (
                        <TableRow key={reservation.authorizationId}>
                            <TableCell>{reservation.authorizationId}</TableCell>
                            <TableCell>{capitalizeText(reservation.customerName)}</TableCell>
                            <TableCell>{reservation.phoneNumber}</TableCell>
                            <TableCell>
                                {formatDateToString(new Date(reservation.fromDate), 'EEEE do MMMM yyyy', true)}
                            </TableCell>
                            <TableCell>
                                {formatDateToString(new Date(reservation.toDate), 'EEEE do MMMM yyyy', true)}
                            </TableCell>
                            <TableCell align='right'>
                                <TableCellMenu
                                    icon={<MoreVertical color='#000' size={20} />}
                                    rowMenuItems={[
                                        {
                                            name: 'Slett reservasjon',
                                            clickHandler: () => handleDeleteReservation(reservation.authorizationId),
                                        },
                                    ]}
                                />
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

export default BikeBoxReservationsTable;
