import { useParams } from 'react-router-dom';
import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';

import { useSelector } from '../../../../../state/hooks';
import BikeBox from '../../../../../views/Bookings/BikeBoxes/BikeBox/BikeBox';

const BikeBoxContainer = () => {
    const { bikeBoxId } = useParams();
    const activeStoreLocation = useSelector(state => state.system.activeStoreLocation);
    if (bikeBoxId === undefined || activeStoreLocation === null) return <SubtleSpinner />;
    return <BikeBox bikeBoxId={bikeBoxId} activeStoreLocation={activeStoreLocation} />;
};

export default BikeBoxContainer;
