import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import PrioritizedBookingsDivisionCard from '../../../../../components/Cards/PrioritizedBookingsDivisionCard';
import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import { setPrioritizedFraction } from '../../../../../state/ducks/bookings/SetPrioritizedFraction';

type Props = {
    activeStoreLocation: string;
    prioritizedCapacityFraction: number;
};

const PrioritizedBookingsDivisionContainer = ({ activeStoreLocation, prioritizedCapacityFraction }: Props) => {
    const [prioritizedValue, setPrioritizedValue] = useState<number>();
    const [standardValue, setStandardValue] = useState<number>();
    const [isPristine, setIsPristine] = useState(true);
    const dispatch = useDispatch();

    const handleChange = (event: ChangeEvent<{ value: unknown; id: string; type: string }>) => {
        const eventValue = Number(event.target.value);
        if (!isNaN(eventValue)) {
            const remainderValue = 100 - eventValue;
            if (remainderValue >= 0 && remainderValue <= 100) {
                setIsPristine(false);
                if (event.target.id === 'prioritizedInput') {
                    setPrioritizedValue(eventValue);
                    setStandardValue(remainderValue);
                } else {
                    setPrioritizedValue(remainderValue);
                    setStandardValue(eventValue);
                }
            }
        }
    };

    const handleSave = () => {
        if (prioritizedValue) dispatch(setPrioritizedFraction(activeStoreLocation, prioritizedValue));
        setIsPristine(true);
    };

    useEffect(() => {
        if (prioritizedCapacityFraction !== null) {
            setPrioritizedValue(Math.round(prioritizedCapacityFraction * 100));
            setStandardValue(Math.round((1 - prioritizedCapacityFraction) * 100));
        }
    }, [prioritizedCapacityFraction]);

    return (
        <>
            {prioritizedValue !== undefined && standardValue !== undefined ? (
                <PrioritizedBookingsDivisionCard
                    prioritizedValue={prioritizedValue}
                    handleChangePrioritizedValue={handleChange}
                    handleChangeStandardValue={handleChange}
                    standardValue={standardValue}
                    handleSave={handleSave}
                    isPristine={isPristine}
                />
            ) : (
                <SubtleSpinner text='Laster...' />
            )}
        </>
    );
};

export default PrioritizedBookingsDivisionContainer;
