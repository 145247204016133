import { useFirebase } from 'react-redux-firebase';
import { useNavigate } from 'react-router-dom';

import Sidebar from '../../../components/Navigation/Sidebar/Sidebar';
import { useFlaggedMessages, useNotifications, useSelector } from '../../../state/hooks';

export default function SidebarContainer() {
    const criticalMetadata = useSelector(state => state.pck.criticalMetadata?.total);
    const notifications = useNotifications();
    const flaggedMessages = useFlaggedMessages();
    const firebase = useFirebase();
    const navigate = useNavigate();

    const handleLogout = async () => {
        await firebase.logout();
        navigate('/logg-inn');
    };

    return (
        <Sidebar
            criticalNotification={criticalMetadata || 0}
            conversationNotification={notifications?.length}
            flaggedNotification={flaggedMessages}
            handleLogout={handleLogout}
        />
    );
}
