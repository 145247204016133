import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { ArticleGroup } from '../../utils/types';

export type Props = {
    primaryArticleGroups: ArticleGroup[];
    secondaryArticleGroups: ArticleGroup[];
    tertiaryArticleGroups: ArticleGroup[];
    primaryArticleGroup: number | string;
    secondaryArticleGroup: number | string;
    tertiaryArticleGroup: number | string;
    handleChange: (event: SelectChangeEvent<string | number>) => void;
    initialValues?: IntialValues;
};

export interface IntialValues {
    primaryArticleGroup: {
        value: number | null;
        disabled?: boolean;
    };
    secondaryArticleGroup: {
        value: number | null;
        disabled?: boolean;
    };
    tertiaryArticleGroup: {
        value: number | null;
        disabled?: boolean;
    };
}

const ArticleGroupsSelectors = ({
    primaryArticleGroups,
    secondaryArticleGroups,
    tertiaryArticleGroups,
    primaryArticleGroup,
    secondaryArticleGroup,
    tertiaryArticleGroup,
    handleChange,
    initialValues,
}: Props) => {
    return (
        <>
            <FormControl>
                <InputLabel id='articleGroup1'>Artikkelgruppe 1</InputLabel>
                <Select
                    labelId='articleGroup1'
                    value={primaryArticleGroup}
                    label='Artikkelgruppe 1'
                    name='primaryArticleGroup'
                    disabled={initialValues?.primaryArticleGroup.disabled}
                    onChange={handleChange}
                >
                    <MenuItem value={'null'}>Alle artikkelgrupper</MenuItem>
                    {primaryArticleGroups.map(ag => (
                        <MenuItem key={ag.articleGroupId} value={ag.articleGroupId}>
                            {ag.articleGroupName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id='articleGroup2'>Artikkelgruppe 2</InputLabel>
                <Select
                    labelId='articleGroup2'
                    value={secondaryArticleGroup}
                    name='secondaryArticleGroup'
                    label='Artikkelgruppe 2'
                    disabled={initialValues?.secondaryArticleGroup.disabled}
                    onChange={handleChange}
                >
                    <MenuItem value={'null'}>Alle artikkelgrupper</MenuItem>
                    {secondaryArticleGroups.map(ag => (
                        <MenuItem key={ag.articleGroupId} value={ag.articleGroupId}>
                            {ag.articleGroupName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl>
                <InputLabel id='articleGroup3'>Artikkelgruppe 3</InputLabel>
                <Select
                    labelId='articleGroup3'
                    value={tertiaryArticleGroup}
                    name='tertiaryArticleGroup'
                    label='Artikkelgruppe 3'
                    disabled={initialValues?.tertiaryArticleGroup.disabled}
                    onChange={handleChange}
                >
                    <MenuItem value={'null'}>Alle artikkelgrupper</MenuItem>
                    {tertiaryArticleGroups.map(ag => (
                        <MenuItem key={ag.articleGroupId} value={ag.articleGroupId}>
                            {ag.articleGroupName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    );
};

export default ArticleGroupsSelectors;
