import { makeStyles } from '../../../state/hooks';

export default makeStyles()(theme => ({
    root: {
        minWidth: 200,
        minHeight: 100,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        padding: theme.spacing(4),
    },
    titleRow: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        marginRight: theme.spacing(2),
        textTransform: 'capitalize',
    },
    boxBodyText: {
        textAlign: 'left',
    },
}));
