import { ButtonBase, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';

import useStyles from './SimpleCardWithChip.styles';

export type Props = {
    chipComponent: JSX.Element;
    cardTitle: string;
    cardBodyTopText?: string;
    cardBodyBottomText?: string;
};

const SimpleCardWithChip = ({ chipComponent, cardTitle, cardBodyTopText, cardBodyBottomText }: Props) => {
    const { classes } = useStyles();

    return (
        <ButtonBase disableRipple>
            <Paper className={classes.root}>
                <Box className={classes.titleRow}>
                    <Typography variant='h3' className={classes.title}>
                        {cardTitle}
                    </Typography>
                    {chipComponent}
                </Box>
                <Box>
                    {cardBodyTopText && (
                        <Typography className={classes.boxBodyText} variant='body1'>
                            {cardBodyTopText}
                        </Typography>
                    )}
                    {cardBodyBottomText && (
                        <Typography className={classes.boxBodyText} variant='body1'>
                            {cardBodyBottomText}
                        </Typography>
                    )}
                </Box>
            </Paper>
        </ButtonBase>
    );
};

export default SimpleCardWithChip;
