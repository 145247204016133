import type { ProductLocation } from '../utils/types';
import { api } from './api';

// Define a service using a base URL and expected endpoints
export const productLocationsApi = api.injectEndpoints({
    endpoints: builder => ({
        getProductLocations: builder.query<
            ProductLocation[],
            { articleId: number; sizeColorId?: number; locationId?: number }
        >({
            query: ({ articleId, sizeColorId, locationId }) => ({
                url: `/productLocations`,
                params: {
                    articleId,
                    sizeColorId,
                    locationId,
                },
                method: 'GET',
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
        }),
        deleteProductLocation: builder.mutation<
            any,
            { locationId: number; articleId?: number; sizeColorId?: number; placementId?: number; count?: number }
        >({
            query: ({ locationId, articleId, sizeColorId, placementId, count }) => ({
                url: `/productLocations`,
                method: 'DELETE',
                params: {
                    articleId,
                    sizeColorId,
                    locationId,
                    placementId,
                    count,
                },
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
        }),
        getLocationPlacements: builder.query<ProductLocation[], { locationId: number }>({
            query: ({ locationId }) => ({
                url: `/inventoryPlacements`,
                params: {
                    locationId,
                },
                method: 'GET',
            }),
            transformResponse: (response: { result: any; error: any }) => response.result,
        }),
    }),
});

export const { useDeleteProductLocationMutation, useGetLocationPlacementsQuery, useGetProductLocationsQuery } =
    productLocationsApi;
