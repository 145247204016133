import { Box, Paper } from '@mui/material';
import NestedBreadcrumbs from '../../Navigation/NestedBreadcrumbs/NestedBreadcrumbs';

type Props = {
    currentPathname: string;
    parentPath: string;
    children: JSX.Element;
};

const NestedResourceNavigationLayout = ({ currentPathname, parentPath, children }: Props) => {
    return (
        <Box sx={{ m: 5 }}>
            <NestedBreadcrumbs currentPathname={currentPathname} parentPath={parentPath} />
            <Paper
                sx={{
                    mt: 4,
                    p: {
                        xs: 4,
                        sm: 5,
                    },
                }}
            >
                {children}
            </Paper>
        </Box>
    );
};

export default NestedResourceNavigationLayout;
