import * as StatisticsFilterTypes from './StatisticsFilter';
import * as GetTurnoverDataTypes from './GetTurnoverData';
import { TimePeriod, ArticleGroup, DataPointSeries, StatisticsFilter } from '../../../utils/types';
import { getArticleGroupName } from '../../../utils/helpers';

interface StatisticsState {
    filter: StatisticsFilter;
    fetchingTurnoverData: boolean;
    fetchingComparisonData: boolean;
    graphData: DataPointSeries[] | null;
    totalTurnover: DataPointSeries[] | null;
    primaryArticleGroups: ArticleGroup[];
    secondaryArticleGroups: ArticleGroup[];
    tertiaryArticleGroups: ArticleGroup[];
}

const thisYear = new Date().getFullYear();
const lastYear = new Date().getFullYear() - 1;

const initialState: StatisticsState = {
    filter: {
        fromDate: new Date('2021-01-01'),
        toDate: new Date('2021-12-31'),
        years: [thisYear, lastYear],
        timePeriod: 'thisYear',
        timePeriodScope: 'entire',
        activeArticleGroupIds: {
            primaryArticleGroups: null,
            secondaryArticleGroups: null,
            tertiaryArticleGroups: null,
        },
        orderTemplateIds: null,
    },
    fetchingTurnoverData: false,
    fetchingComparisonData: false,
    graphData: null,
    totalTurnover: null,
    primaryArticleGroups: [],
    secondaryArticleGroups: [],
    tertiaryArticleGroups: [],
};

export default function statisticsReducer(state = initialState, action: any) {
    const { type, payload } = action;

    switch (type) {
        case StatisticsFilterTypes.SET_TIME_PERIOD: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    timePeriod: payload.timePeriod as TimePeriod,
                },
            };
        }

        case StatisticsFilterTypes.SET_TIME_PERIOD_SCOPE: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    timePeriodScope: payload.timePeriodScope,
                },
            };
        }

        case StatisticsFilterTypes.RESET_STATISTICS_FILTER: {
            return {
                ...state,
                filter: {
                    ...initialState.filter,
                },
            };
        }

        case StatisticsFilterTypes.SET_YEARS: {
            const years: number[] = payload.years.sort().reverse();

            return {
                ...state,
                filter: {
                    ...state.filter,
                    years,
                },
            };
        }

        case StatisticsFilterTypes.SET_ACTIVE_ARTICLE_GROUPS: {
            const articleGroup = getArticleGroupName(payload.articleGroupLevel);

            return {
                ...state,
                filter: {
                    ...state.filter,
                    activeArticleGroupIds: {
                        ...state.filter.activeArticleGroupIds,
                        [articleGroup]: payload.activeArticleGroupIds,
                    },
                },
            };
        }

        case StatisticsFilterTypes.SET_ORDER_TEMPLATE_IDS: {
            return {
                ...state,
                filter: {
                    ...state.filter,
                    orderTemplateIds: payload.orderTemplateIds as number[],
                },
            };
        }

        case StatisticsFilterTypes.GET_ARTICLE_GROUPS_SUCCESS: {
            const articleGroup = getArticleGroupName(payload.articleGroupLevel);
            return {
                ...state,
                [articleGroup]: payload.articleGroups,
            };
        }

        case GetTurnoverDataTypes.GET_GRAPH_DATA_STARTED: {
            return {
                ...state,
                fetchingTurnoverData: true,
            };
        }

        case GetTurnoverDataTypes.GET_GRAPH_DATA_SUCCESS: {
            return {
                ...state,
                fetchingTurnoverData: false,
                graphData: payload.data as DataPointSeries[],
            };
        }

        case GetTurnoverDataTypes.GET_GRAPH_DATA_FAILED: {
            return {
                ...state,
                fetchingTurnoverData: false,
                fetchingComparisonData: false,
            };
        }

        case GetTurnoverDataTypes.GET_TURNOVER_COMPARISON_DATA_STARTED: {
            return {
                ...state,
                fetchingComparisonData: true,
            };
        }

        case GetTurnoverDataTypes.GET_TURNOVER_COMPARISON_DATA_SUCCESS: {
            return {
                ...state,
                totalTurnover: payload.data as DataPointSeries[],
                fetchingComparisonData: false,
            };
        }

        default: {
            return state;
        }
    }
}
