import { Box, Chip, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import SimpleCardWithChip from '../../../../../components/Cards/SimpleCardWithChip/SimpleCardWithChip';
import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import { useGetBikeBoxesQuery } from '../../../../../services/BikeBoxes';
import { formatDateToString } from '../../../../../utils/date';
import { capitalizeText } from '../../../../../utils/format';

type Props = {
    activeStoreLocation: string;
};

const BikeBoxesContainer = ({ activeStoreLocation }: Props) => {
    const { data, isLoading, isSuccess } = useGetBikeBoxesQuery({ storeName: activeStoreLocation });

    return (
        <>
            <Box sx={{ mt: 4 }}>
                {!isLoading && isSuccess && data ? (
                    <Grid container sx={{ mt: 1 }} spacing={2}>
                        {data.map(bikeBox => {
                            const isActive = bikeBox.activeReservation !== null;
                            const bottomText = bikeBox.activeReservation
                                ? `${formatDateToString(
                                      new Date(bikeBox.activeReservation?.fromDate) ?? null,
                                      'do MMMM',
                                      true
                                  )} - ${formatDateToString(
                                      new Date(bikeBox.activeReservation?.toDate) ?? null,
                                      'do MMMM',
                                      true
                                  )}`
                                : undefined;
                            return (
                                <Grid item key={bikeBox.id} xs={12} sm={'auto'}>
                                    <Link to={bikeBox.id.toString()} style={{ textDecoration: 'none' }}>
                                        <SimpleCardWithChip
                                            cardTitle={bikeBox.name}
                                            cardBodyTopText={capitalizeText(
                                                bikeBox.activeReservation?.customerName ?? ''
                                            )}
                                            cardBodyBottomText={bottomText}
                                            chipComponent={
                                                <Chip
                                                    label={isActive ? 'Opptatt' : 'Ledig'}
                                                    color={isActive ? 'error' : 'success'}
                                                />
                                            }
                                        />
                                    </Link>
                                </Grid>
                            );
                        })}
                    </Grid>
                ) : (
                    <SubtleSpinner />
                )}
            </Box>
        </>
    );
};

export default BikeBoxesContainer;
