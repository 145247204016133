import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { ChangeEvent, useState } from 'react';

import { ArticleGroup, LocationType, NewLocation } from '../../../utils/types';
import Axis from '../../Layouts/Axis/Axis';
import Column from '../../Layouts/Column/Column';
import Modal from '../Modal/Modal';
import useStyles from './LocationModal.styles';

interface IntialValues {
    name: {
        value: string;
        disabled?: boolean;
    };
    maxSize: {
        value: number | undefined;
        disabled?: boolean;
    };
    locationType: { value: LocationType; disabled?: boolean };
    primaryArticleGroup: { value: number | null; disabled?: boolean };
    secondaryArticleGroup: { value: number | null; disabled?: boolean };
    tertiaryArticleGroup: { value: number | null; disabled?: boolean };
}

export type Props = {
    open: boolean;
    title: string;
    primaryArticleGroups: ArticleGroup[];
    secondaryArticleGroups: ArticleGroup[];
    tertiaryArticleGroups: ArticleGroup[];
    initialValues?: IntialValues;
    handleToggleModal: () => void;
    handleSaveLocation: (location: NewLocation) => void;
};

const LocationInfoModal = ({
    open,
    title,
    primaryArticleGroups,
    secondaryArticleGroups,
    tertiaryArticleGroups,
    initialValues,
    handleToggleModal,
    handleSaveLocation,
}: Props) => {
    const { classes } = useStyles();

    const [name, setName] = useState(initialValues?.name.value ?? '');
    const [maxSize, setMaxSize] = useState<number | undefined>(initialValues?.maxSize.value ?? 10);
    const [locationType, setLocationType] = useState<LocationType>(initialValues?.locationType.value ?? 'one-to-one');
    const [nameError, setNameError] = useState(false);
    const [primaryArticleGroup, setPrimaryArticleGroup] = useState<number | string>(
        initialValues?.primaryArticleGroup.value ?? 'null'
    );
    const [secondaryArticleGroup, setSecondaryArticleGroup] = useState<number | string>(
        initialValues?.secondaryArticleGroup.value ?? 'null'
    );
    const [tertiaryArticleGroup, setTertiaryArticleGroup] = useState<number | string>(
        initialValues?.tertiaryArticleGroup.value ?? 'null'
    );

    const onChangeName = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setName(event.target.value);
        setNameError(false);
    };

    const onCreateLocation = () => {
        if (name === '') setNameError(true);
        else {
            handleSaveLocation({
                name,
                locationType,
                maxSize,
                articleGroup1Id: Number(primaryArticleGroup),
                articleGroup2Id: secondaryArticleGroup === 'null' ? null : Number(secondaryArticleGroup),
                articleGroup3Id: tertiaryArticleGroup === 'null' ? null : Number(tertiaryArticleGroup),
            });
        }
    };

    return (
        <Modal maxWidth='sm' openModal={open} toggleModal={handleToggleModal}>
            <Box className={classes.root}>
                <Typography sx={{ marginBottom: 4 }} variant='h3'>
                    {title}
                </Typography>
                <Column gap={3}>
                    <TextField
                        error={nameError}
                        helperText={nameError ? 'Navn må fylles inn.' : ''}
                        label='Navn'
                        id='my-input'
                        variant='outlined'
                        value={name}
                        name='locationName'
                        disabled={initialValues?.name.disabled}
                        onChange={onChangeName}
                    />
                    <Axis direction='column' gap={3}>
                        <FormControl className={classes.dropdowns}>
                            <InputLabel id='locationType'>Lokasjonstype</InputLabel>
                            <Select
                                labelId='locationType'
                                value={locationType}
                                label='Lokasjonstype'
                                disabled={initialValues?.locationType.disabled}
                                onChange={event => setLocationType(event.target.value as LocationType)}
                            >
                                <MenuItem value={'one-to-one'}>1 til 1</MenuItem>
                                <MenuItem value={'one-to-many'}>1 til mange</MenuItem>
                            </Select>
                        </FormControl>
                        <FormControl className={classes.dropdowns}>
                            <InputLabel id='articleGroup1'>Artikkelgruppe 1</InputLabel>
                            <Select
                                labelId='articleGroup1'
                                value={primaryArticleGroup}
                                label='Artikkelgruppe 1'
                                disabled={initialValues?.primaryArticleGroup.disabled}
                                onChange={event => {
                                    setPrimaryArticleGroup(event.target.value);
                                }}
                            >
                                <MenuItem value={'null'}>Alle artikkelgrupper</MenuItem>
                                {primaryArticleGroups.map(ag => (
                                    <MenuItem key={ag.articleGroupId} value={ag.articleGroupId}>
                                        {ag.articleGroupName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.dropdowns}>
                            <InputLabel id='articleGroup2'>Artikkelgruppe 2</InputLabel>
                            <Select
                                labelId='articleGroup2'
                                value={secondaryArticleGroup}
                                label='Artikkelgruppe 2'
                                disabled={initialValues?.secondaryArticleGroup.disabled}
                                onChange={event => setSecondaryArticleGroup(event.target.value)}
                            >
                                <MenuItem value={'null'}>Alle artikkelgrupper</MenuItem>
                                {secondaryArticleGroups.map(ag => (
                                    <MenuItem key={ag.articleGroupId} value={ag.articleGroupId}>
                                        {ag.articleGroupName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl className={classes.dropdowns}>
                            <InputLabel id='articleGroup3'>Artikkelgruppe 3</InputLabel>
                            <Select
                                labelId='articleGroup3'
                                value={tertiaryArticleGroup}
                                label='Artikkelgruppe 3'
                                disabled={initialValues?.tertiaryArticleGroup.disabled}
                                onChange={event => setTertiaryArticleGroup(event.target.value)}
                            >
                                <MenuItem value={'null'}>Alle artikkelgrupper</MenuItem>
                                {tertiaryArticleGroups.map(ag => (
                                    <MenuItem key={ag.articleGroupId} value={ag.articleGroupId}>
                                        {ag.articleGroupName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Axis>
                    {locationType === 'one-to-many' && (
                        <TextField
                            label='Maks antall'
                            type={'number'}
                            disabled={initialValues?.maxSize.disabled}
                            id='my-input'
                            variant='outlined'
                            value={maxSize}
                            onChange={event => setMaxSize(parseInt(event.target.value))}
                            helperText='Sett en maks-kapasitet for lokasjonen.'
                        />
                    )}
                    <Box className={classes.buttonBox}>
                        <Button variant='text' onClick={handleToggleModal}>
                            Avbryt
                        </Button>
                        <Button variant='contained' onClick={onCreateLocation}>
                            Lagre
                        </Button>
                    </Box>
                </Column>
            </Box>
        </Modal>
    );
};

export default LocationInfoModal;
