import { Box, Chip, Grid } from '@mui/material';
import { Link } from 'react-router-dom';

import { useGetPromotionalCodesQuery } from '../../../../services/PromotionalCodes';
import SubtleSpinner from '../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import SimpleCardWithChip from '../../../../components/Cards/SimpleCardWithChip/SimpleCardWithChip';
import { formatDateToString } from '../../../../utils/date';

type Props = { storeName: string };

const PromotionalCodesContainer = ({ storeName }: Props) => {
    const { data, isLoading, isSuccess } = useGetPromotionalCodesQuery({ storeName });
    return (
        <Box sx={{ mt: 4 }}>
            {!isLoading && isSuccess && data ? (
                <Grid container sx={{ mt: 1 }} spacing={2}>
                    {data.map(promoCode => {
                        const isActive = promoCode.isActive;
                        const topText = promoCode.startDate
                            ? formatDateToString(new Date(promoCode.startDate) ?? null, 'do MMM yyyy', true)
                            : 'ingen';
                        const bottomText = promoCode.endDate
                            ? formatDateToString(new Date(promoCode.endDate) ?? null, 'do MMM yyyy', true)
                            : 'ingen';
                        return (
                            <Grid item key={promoCode.id} xs={12} sm={'auto'}>
                                <Link to={promoCode.id} style={{ textDecoration: 'none' }}>
                                    <SimpleCardWithChip
                                        cardTitle={promoCode.name}
                                        cardBodyTopText={'Start-dato: ' + topText}
                                        cardBodyBottomText={'Slutt-dato: ' + bottomText}
                                        chipComponent={
                                            <Chip
                                                label={isActive ? 'Aktiv' : 'Inaktiv'}
                                                color={isActive ? 'success' : 'error'}
                                            />
                                        }
                                    />
                                </Link>
                            </Grid>
                        );
                    })}
                </Grid>
            ) : (
                <SubtleSpinner />
            )}
        </Box>
    );
};

export default PromotionalCodesContainer;
