import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import BookingCalendarCapacityContainer from '../../../containers/PageSpecific/Bookings/Calendar/BookingCalendarCapacityContainer';
import { useSelector } from '../../../state/hooks';

const BookingCalendar = () => {
    const activeStoreLocation = useSelector(state => state.system.activeStoreLocation);
    return (
        <>
            {!activeStoreLocation ? (
                <SubtleSpinner style={{ marginTop: 50 }} />
            ) : (
                <BookingCalendarCapacityContainer activeStoreLocation={activeStoreLocation} />
            )}
        </>
    );
};

export default BookingCalendar;
