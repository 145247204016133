import {
    isTomorrow,
    isToday,
    formatDistanceToNowStrict,
    getDay,
    getWeeksInMonth,
    getDaysInMonth,
    startOfYear,
    endOfYear,
    startOfMonth,
    endOfMonth,
    startOfWeek,
    endOfWeek,
    formatISO,
    startOfQuarter,
    endOfQuarter,
} from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';
import { nb } from 'date-fns/locale';
import { formatWithOptions } from 'date-fns/fp';

import { TimePeriod, TimePeriodScope } from './types';

/**
 * Converts a date object to a formatted string.
 * @param date The date object to convert.
 * @param dateFormat Optional date format (date-fns)
 * @param forceDate Optional ability to force a date, instead of "tomorrow" if date is tomorrow.
 * @returns A formatted, readable date as string.
 */
export function formatDateToString(
    date: Date | null,
    dateFormat: string = 'cccc do MMM',
    forceDate: boolean = false
): string {
    if (!date) return 'ingen dato';
    const utcDate = zonedTimeToUtc(date, 'Europe/Oslo');

    if (!forceDate) {
        if (isToday(utcDate)) return 'I dag';
        if (isTomorrow(utcDate)) return 'I morgen';
    }

    const formatDate = formatWithOptions({ locale: nb }, dateFormat);
    const formattedDate = formatDate(utcDate);
    return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
}

export function formatPastDate(date: number | Date | null | undefined): string {
    if (!date) return 'ingen dato';
    return formatDistanceToNowStrict(date, { locale: nb });
}

// TODO Replace this with formatDateToString function
export function formatNormalDate(date: Date | null): string {
    return formatDateToString(date, 'do MMM yyyy', true);
}

/**
 * Converts a date object to its position in the week as a number.
 * @param date Date to convert.
 * @returns A number between 0 and 6, where 0 is monday and 6 is sunday.
 */
export function formatWeekday(date: Date): number {
    let weekDay = getDay(date) - 1;
    if (weekDay < 0) weekDay = 6;
    return weekDay;
}

/**
 * Finds the first date of the month, and then calculates how many blank spaces
 * there are before the first date, and after the last,
 * @param year The given year
 * @param month The given month
 * @returns An array with two values: blank spaces before the first date, and blank spaces after the last date.
 */
export function calculateBlankCalendarDays(year: number, month: number): [number, number] {
    const date = new Date(year, month);
    const weeksInMonths = getWeeksInMonth(date, { weekStartsOn: 1 });
    const numBlanks = weeksInMonths * 7 - getDaysInMonth(date);
    const monthStartWeekdayNumber = getWeekdayNumber(date);

    return [monthStartWeekdayNumber - 1, numBlanks - monthStartWeekdayNumber + 1];
}

/**
 * Gets the day of the week as a number.
 * @param date
 * @returns A number, where 1 is monday and 7 is sunday.
 */
export function getWeekdayNumber(date: Date) {
    return date.getDay() === 0 ? 7 : date.getDay();
}

/**
 * Gets the start and end date of a specified time period.
 * @param timePeriod When to start and when to end
 * @param years Which year to create the dates from. Defaults to this year.
 * @param today Optional, overrides the default day (today's date).
 * @returns An object with from- and to-dates formatted as ISO8601 strings.
 */
export function findDatesFromTimePeriod(
    timePeriod: TimePeriod,
    timePeriodScope: TimePeriodScope,
    years: number[],
    today?: Date
): { fromDate: string; toDate: string } {
    let result: { fromDate: Date; toDate: Date };

    const _years = years.sort();

    const firstYear = _years[0];
    const lastYear = _years[_years.length - 1];

    const fromDate = today ?? new Date();
    const toDate = new Date(lastYear, fromDate.getMonth(), fromDate.getDate());

    fromDate.setFullYear(firstYear);
    switch (timePeriod) {
        case 'thisYear': {
            result = {
                fromDate: startOfYear(fromDate),
                toDate: endOfYear(toDate),
            };
            break;
        }

        case 'thisQuarter': {
            result = {
                fromDate: startOfQuarter(fromDate),
                toDate: endOfQuarter(toDate),
            };
            break;
        }
        case 'thisMonth': {
            result = {
                fromDate: startOfMonth(fromDate),
                toDate: endOfMonth(toDate),
            };
            break;
        }
        case 'thisWeek': {
            result = {
                fromDate: startOfWeek(fromDate, { weekStartsOn: 1 }),
                toDate: endOfWeek(toDate, { weekStartsOn: 1 }),
            };
            break;
        }
    }
    if (timePeriodScope === 'soFar') {
        result.toDate = toDate;
        result.toDate.setDate(toDate.getDate());
    }
    return {
        fromDate: formatISO(result.fromDate, { representation: 'date' }),
        toDate: formatISO(result.toDate, { representation: 'date' }),
    };
}
