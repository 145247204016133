import { useParams } from 'react-router-dom';

import { useSelector } from '../../../../../state/hooks';
import PromotionalCode from '../../../../../views/Bookings/PromotionalCodes/PromotionalCode';
import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';

const PromotionalCodeContainer = () => {
    const activeStoreLocation = useSelector(state => state.system.activeStoreLocation);
    const { promotionalCodeId } = useParams();

    if (promotionalCodeId === undefined || activeStoreLocation === null) return <SubtleSpinner />;
    return <PromotionalCode promoCodeId={promotionalCodeId} activeStoreLocation={activeStoreLocation} />;
};

export default PromotionalCodeContainer;
