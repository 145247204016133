import { ServiceCapacity, SpecialCapacity } from '../utils/types';
import { api } from './api';

export const bookingCapacityApi = api.injectEndpoints({
    endpoints: builder => ({
        getServiceCapacity: builder.query<
            ServiceCapacity[][],
            {
                storeName: string;
                numberOfMonths: number;
                brand: string[];
                timezoneOffset: number;
                soldAtStore?: boolean;
            }
        >({
            query: ({ storeName, numberOfMonths, brand, timezoneOffset, soldAtStore }) => {
                let url = `/serviceCapacity?storeName=${storeName}&numberOfMonths=${numberOfMonths}&brand[]=${brand}&timezoneOffset=${timezoneOffset}`;
                if (soldAtStore) url += `&soldAtStore=${soldAtStore}`;
                return {
                    url,
                    method: 'GET',
                };
            },
            providesTags: ['BookingCapacity'],
        }),
        getSpecialCapacity: builder.query<SpecialCapacity[], { storeName: string }>({
            query: ({ storeName }) => ({
                url: `/specialCapacity?storeName=${storeName}`,
                method: 'GET',
            }),
            providesTags: ['SpecialCapacity'],
            transformResponse: (response: { result: any; error: any }) => response.result,
        }),
        insertSpecialCapacity: builder.mutation<any, { storeName: string; date: string; capacity: number }>({
            query: ({ storeName, date, capacity }) => ({
                url: `/specialCapacity?storeName=${storeName}&date=${date}&capacity=${capacity}`,
                method: 'POST',
            }),
            invalidatesTags: ['BookingCapacity', 'SpecialCapacity'],
        }),
        updateSpecialCapacity: builder.mutation<any, { storeName: string; id: string; capacity: number }>({
            query: ({ storeName, id, capacity }) => ({
                url: `/specialCapacity?storeName=${storeName}&id=${id}&capacity=${capacity}`,
                method: 'PUT',
            }),
            invalidatesTags: ['BookingCapacity', 'SpecialCapacity'],
        }),
        deleteSpecialCapacity: builder.mutation<any, { id: string; storeName: string }>({
            query: ({ id, storeName }) => ({
                url: `/specialCapacity?storeName=${storeName}&id=${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['BookingCapacity', 'SpecialCapacity'],
        }),
    }),
});

export const {
    useGetServiceCapacityQuery,
    useGetSpecialCapacityQuery,
    useInsertSpecialCapacityMutation,
    useUpdateSpecialCapacityMutation,
    useDeleteSpecialCapacityMutation,
} = bookingCapacityApi;
