import { ExtendedFirebaseInstance } from 'react-redux-firebase';
import { differenceInDays, format, isAfter, isBefore, isSameDay, subDays } from 'date-fns';
import { nb } from 'date-fns/locale';

import {
    OrderLine,
    FilterOption,
    Notification,
    OrderTemplate,
    Employee,
    GraphLineData,
    DataPointSeries,
    DataPoint,
    AggregateTimePeriods,
    SortOrder,
    ArticlePlacement,
    ProductLocation,
    Message,
    ArticleGroupName,
    BikeBoxAvailability,
    FilterState,
} from './types';
import Customer from '../models/Customer';
import Order from '../models/Order';
import { DataComparison, DataComparisonValues } from './types';
import { FetchError } from '../models/AppError';
import { Article } from '../models/Article';
import PhoneNumber from '../models/PhoneNumber';
import tinycolor from 'tinycolor2';
import { getGenericOrderError } from './errorHelpers';
import _uniq from 'lodash/uniq';
import _map from 'lodash/map';
import _flatMap from 'lodash/flatMap';
import _filter from 'lodash/filter';
import _orderBy from 'lodash/orderBy';
import _sortBy from 'lodash/sortBy';
import _intersection from 'lodash/intersection';

/**
 * Simple helper function to parse a boolean
 * @param boolean A string representation of a boolean that can be 'true' or '1'
 * @returns A boolean
 */
export function parseBoolean(boolean: string) {
    switch (boolean) {
        case 'true':
        case '1':
            return true;
        default:
            return false;
    }
}

/**
 * Returns a list of filter options
 * @param list The list to extract properties from
 * @param labelProp The label prop to use
 * @param idProp The id prop to use
 * @param firstOption Optional first option override
 * @returns A list of FilterOption objects
 */
// TODO replace any[] with a generic type?
export const getFilterOptions = (
    list: any[],
    labelProp: string,
    idProp: string,
    firstOption?: FilterOption
): FilterOption[] => {
    const result = list.map(item => ({
        id: item[idProp],
        label: item[labelProp],
    }));

    if (firstOption) result.unshift(firstOption);

    return result;
};

// Calculates the total price of the orderlines
// TODO use array reduce instead?
export function calculateTotalOrderLinesPrice(orderLines: OrderLine[] | null | undefined): number {
    let totalPrice = 0;
    if (orderLines && orderLines.length) {
        orderLines.forEach((ol: OrderLine) => {
            totalPrice += ol.price;
        });
    }
    return totalPrice;
}

/**
 * Checks if the customer has any notifications.
 *
 * @param customer The customer instance
 * @param notifications A list of Notification objects
 * @returns True if customer has notifications, false if not
 */
export function hasCustomerNotifications(customer: Customer, notifications: Notification[]): boolean {
    const nums = customer.getPhoneNumbers() ?? [];

    for (const noti of notifications) {
        for (const num of nums) {
            if (noti.phoneNumber.equals(num)) {
                return true;
            }
        }
    }

    return false;
}

/**
 * Check if environment is production
 * @params envs optional env variables. If left out, function will use default process.env
 * @returns true if node env is prod, false if not
 */
export function isProdEnvironment(envs: NodeJS.ProcessEnv = process.env): boolean {
    return envs?.NODE_ENV === 'production' ? true : false;
}

/**
 * Extract unique metadata (order templates and employees) from orders given
 * @param orders input orders to extract from
 * @returns tuple of two lists: order templates and employees in the input orders
 */
export function extractMetaDataFromOrders(orders: Order[]): [OrderTemplate[], Employee[]] {
    const templatesDict: { [key: number]: OrderTemplate } = {};
    const employeesDict: { [key: number]: Employee } = {};

    for (const { templateId, templateName, employeeId, employeeName } of orders) {
        if (!(templateId in templatesDict))
            templatesDict[templateId] = { orderTemplateId: templateId, orderTemplateName: templateName };

        if (!(employeeId in employeesDict)) employeesDict[employeeId] = { id: employeeId, name: employeeName };
    }

    return [Object.values(templatesDict), Object.values(employeesDict)];
}

/**
 * Helper to fetch data from an authenticated API (project default backend).
 *
 * @param getFirebase function that returns a firebase instance
 * @param endpoint endpoint to hit
 * @param method request method – default: GET
 * @param urlBase a another base url (auth might not work with non-default backend API)
 * @param headers request headers - default: empty Headers instance
 * @returns promise of the fetch response
 * @throws AppError if response status is anything other than the 200s, or TypeError if fetch
 * parameters are invalid
 */
export async function authFetch(
    getFirebase: () => ExtendedFirebaseInstance,
    endpoint: string,
    method: 'GET' | 'POST' | 'DELETE' | 'PUT' = 'GET',
    body?: object,
    urlBase: string = 'http://localhost:3001/v1',
    headers: Headers = new Headers()
): Promise<Response> {
    const token = await getFirebase().auth().currentUser?.getIdToken();
    headers.append('authorization', 'Bearer ' + token);

    const init: RequestInit = {
        headers: headers,
        method: method,
    };

    if (body) {
        init.body = JSON.stringify(body);
        headers.append('Content-Type', 'application/json');
    }

    const response = await fetch(urlBase + endpoint, init);

    if (response.ok) {
        return response;
    } else {
        const error = await response.json();
        throw new FetchError(error);
    }
}

/**
 * Converts data to the graph format.
 * @param data The data to transform
 * @param aggregateOn The value to aggregate on. This is for generating x-axis labels
 * @param yValue The parameter to use from the data object, e.g. "netAmount" or "contribution".
 * @returns An array of data to be used in the graph.
 */
export function shapeGraphData(
    data: DataPointSeries[],
    aggregateOn: AggregateTimePeriods,
    yValue: keyof DataPoint
): GraphLineData[] {
    const datapoints = data.map(d => {
        return getGraphDataFromDatapoint(d, yValue, aggregateOn);
    });

    return datapoints;
}

/**
 * Helper function to get graph-formatted data for a DatapointSeries-object.
 * @param data The data to format
 * @param yValue The parameter to use from the data object, e.g. "netAmount" or "contribution".
 * @param aggregateOn The aggregate value.
 * @returns An object representing a single point in the graph, with an x- and y-value.
 */
export function getGraphDataFromDatapoint(
    data: DataPointSeries,
    yValue: keyof DataPoint,
    aggregateOn: AggregateTimePeriods
): GraphLineData {
    const _data: GraphLineData['data'] = data.datapoints.map(datapoint => {
        const xValue = datapoint.aggregateKey;

        return {
            x: getXAxisValueFromTimePeriod(xValue, data.year, aggregateOn),
            y: datapoint[yValue] ?? 0,
        };
    });
    return {
        id: data.year.toString(),
        data: _data,
    };
}

/**
 * Helper function to get the label, based on what time period is given.
 * @param value The aggregateKey for the datapoint, which will be converted to a string.
 * @param year The year chosen.
 * @param aggregateOn The aggregate value.
 * @returns A formatted string representing the x-value, e.g. "January" for 0.
 */
function getXAxisValueFromTimePeriod(value: number, year: number, aggregateOn: AggregateTimePeriods): string {
    switch (aggregateOn) {
        case AggregateTimePeriods.thisYear:
        case AggregateTimePeriods.thisQuarter:
            const month = value - 1; // month is 0-based
            return format(new Date(year, month, 1), 'MMM', { locale: nb });
        case AggregateTimePeriods.thisMonth:
            return value.toString();
        case AggregateTimePeriods.thisWeek:
            return format(new Date(year, 0, value), 'eeee');
    }
}

/**
 * Converts datapoints to a data comparison list.
 * @param datapoints The datapoints to compare.
 * @param datapointValue The key value from the Datapoint to use, e.g. 'netAmount' or 'contribution'. Defaults to 'netAmount'.
 * @returns An object with comparison data, with difference array which only compares the first two datapoints.
 */
export function shapeTurnoverDataComparison(
    datapoints: DataPointSeries[],
    datapointValue: keyof DataPoint = 'netAmount'
): DataComparison {
    let data: DataComparisonValues[] = [];
    datapoints.forEach(datapoint => {
        if (datapoint.datapoints.length) {
            data.push({
                timePeriod: datapoint.year.toString(),
                data: datapoint.datapoints[0][datapointValue],
            });
        }
    });

    // Finding the difference on only the first two datapoints, in percentage and value
    const difference: number[] = [];

    if (datapoints.length > 1 && datapoints[0].datapoints.length && datapoints[1].datapoints.length) {
        const firstDatapoint = datapoints[0].datapoints[0][datapointValue];
        const secondDatapoint = datapoints[1].datapoints[0][datapointValue];

        if (firstDatapoint && secondDatapoint) {
            const percentageDifference = relativeDifference(secondDatapoint, firstDatapoint);
            const turnoverDifference = firstDatapoint - secondDatapoint;

            difference.push(turnoverDifference);
            difference.push(percentageDifference);
        }
    }

    return {
        data,
        difference,
    };
}

function relativeDifference(startingValue: number, finalValue: number) {
    return (finalValue - startingValue) / startingValue;
}

export function createFailedAction(type: string): (error: unknown) => { type: string; payload: { error: unknown } } {
    return (error: unknown) => ({
        type,
        payload: { error },
    });
}

export const createUrlParamsFromArray = (array: string[] | number[], parameterKey: string): string => {
    let result = '';
    array.forEach((s, i) => {
        if (i === 0) result += `${parameterKey}[]=${s}`;
        else result += `&${parameterKey}[]=${s}`;
    });

    return result;
};

export const getArticleGroupName = (articleGroupLevel: 1 | 2 | 3): ArticleGroupName => {
    switch (articleGroupLevel) {
        case 1:
            return 'primaryArticleGroups';
        case 2:
            return 'secondaryArticleGroups';
        case 3:
            return 'tertiaryArticleGroups';
    }
};

/**
 * Helper function that formats a string consisting of the name, color and size of an Article variant.
 * @returns A formatted string.
 */
export const getArticleVariantName = (articleName: string, colorName: string | null, sizeName: string | null) => {
    const color = colorName ? ', ' + colorName : '';
    const size = sizeName ? ', ' + sizeName : '';
    return articleName + color + size;
};

export const getArticleColorOptions = (article: Article) => {
    const list: { colorId: number; colorName: string }[] = [];
    const idList: number[] = [];

    article.variants?.forEach(v => {
        if (v.color?.colorId && !idList.includes(v.color?.colorId)) {
            idList.push(v.color?.colorId);
            list.push(v.color);
        }
    });

    return list;
};

export const getArticleSizeOptions = (article: Article) => {
    const list: { sizeId: number; sizeName: string }[] = [];
    const idList: number[] = [];

    article.variants?.forEach(v => {
        if (v.size?.sizeId && !idList.includes(v.size.sizeId)) {
            idList.push(v.size.sizeId);
            list.push(v.size);
        }
    });

    return list;
};

export const getStockColor = (stock: number, onlyError?: boolean) => {
    if (stock > 0 && !onlyError) return 'success.main';
    else if (stock < 0) return 'error.main';
    return 'neutrals.400';
};

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

export function getComparator<Key extends keyof any>(
    order: SortOrder,
    orderBy: Key
): (a: { [key in Key]: number | string | null }, b: { [key in Key]: number | string | null }) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Function to generate a string list
 * @param startValue The numeric value to start from
 * @param endValue The numeric value to end on, inclusively
 * @param prefix An optional prefix as a string
 * @returns An array of strings
 */
export function generateStringList(startValue: number, endValue: number, prefix: string = '') {
    const list = [];
    for (let i = startValue; i <= endValue; i++) {
        list.push(prefix + i.toString());
    }
    return list;
}

/**
 * Creates a list of strings, where the newItem is either added or removed if it already exists.
 * @param newItem New item to enter or remove from the list
 * @param selected The current list of items
 * @returns A new edited list
 */
export function createSelectedList(newItem: string, selected: readonly string[]): readonly string[] {
    const selectedIndex = selected.indexOf(newItem);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
        // New item does not exist
        newSelected = newSelected.concat(selected, newItem);
    } else if (selectedIndex === 0) {
        // If new item is first in array
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        // if new item is last in array
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        // If new item is somewhere in the middle of the array
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    return newSelected;
}

/**
 * Typeguard function to check if the value is of type T
 * @param value The value to check
 * @returns If the value is not null or undefined
 */
export function isNotNullOrUndefined<T>(value: T | null | undefined): value is T {
    return !(value === null || value === undefined);
}

export function toArticlePlacement(placement: ProductLocation): ArticlePlacement {
    return {
        articleId: placement.articleId,
        placementId: placement.placementId,
        placementName: placement.placementName,
        articleName: placement.articleName,
        articleNumber: placement.articleNumber,
        count: placement.count,
        sizeColorId: placement.sizeColorId,
        sizeName: placement.size,
        colorName: placement.color,
        supplierArticleNumber: placement.supplierArticleNumber,
    };
}

/**
 * Function to concatenate messages that have been split up when sending. The text-property is appended to the first message object's text property.
 * @param messages The list of messages to check, in an ascending order.
 * @param maxMillisecondsBetweenMessages The maximum milliseconds between messages should be concatenated
 * @returns A new list with concatenated messages.
 */
export function concatenateMessages(messages: Message[], maxMillisecondsBetweenMessages: number = 1500): Message[] {
    const newMessageArray: Message[] = [];
    messages.forEach((message, index) => {
        if (index > 0) {
            const previousMessage: Message = messages[index - 1];
            if (message.date.toMillis() - previousMessage.date.toMillis() < maxMillisecondsBetweenMessages) {
                newMessageArray[newMessageArray.length - 1] = {
                    ...newMessageArray[newMessageArray.length - 1],
                    text: newMessageArray[newMessageArray.length - 1].text + message.text,
                };
            } else newMessageArray.push(message);
        } else newMessageArray.push(message);
    });

    return newMessageArray;
}

export function moveFirstArrayElementToEnd(array: any[]) {
    const temp = [...array];
    temp.push(temp.shift());
    return temp;
}

export function moveLastArrayElementToStart(array: any[]) {
    const temp = [...array];
    const x: any[] = [temp[temp.length - 1]].concat(temp);
    x.pop();
    return x;
}

/**
 * Helper function to check if a phone number has a notification
 * @param notifications The list of notifications to check
 * @param phoneNumbers The list of phone numbers to check
 * @returns A boolean indicating if the phone number has a notification
 */
export function checkIfNewMessage(notifications: Notification[], phoneNumbers: PhoneNumber[] | null) {
    if (notifications.length > 0 && phoneNumbers) {
        for (const phone of phoneNumbers) {
            for (const noti of notifications) {
                if (phone.equals(noti.phoneNumber)) {
                    return true;
                }
            }
        }
    }
    return false;
}

export function searchOrders(orders: Order[], search: string): Order[] {
    return orders.filter(order => {
        return (
            order.customer?.customerName.toLowerCase().includes(search.toLowerCase()) ||
            order.customer?.getPhoneNumbers()?.some(phone => phone.get().includes(search))
        );
    });
}

/**
 * Returns a list of all weekday names in the current locale
 *  */
export function getWeekDays(locale: string = 'nb-NO'): string[] {
    var baseDate = new Date(Date.UTC(2017, 0, 2)); // A random Monday
    var weekDays = [];
    for (let i = 0; i < 7; i++) {
        weekDays.push(baseDate.toLocaleDateString(locale, { weekday: 'long' }));
        baseDate.setDate(baseDate.getDate() + 1);
    }
    return weekDays;
}

/**
 * Function that returns a readable text color based on the background color.
 * @param backgroundColor The background color
 * @param mostReadableArguments (optional) Arguments to pass to tinycolor.mostReadable function
 * @returns A readable text color
 */
export function getReadableTextColor(
    backgroundColor: string,
    mostReadableArguments?: tinycolor.MostReadableArgs
): tinycolor.Instance {
    const textColorDarken = tinycolor(backgroundColor).darken(75);
    const textColorLighten = tinycolor(backgroundColor).lighten(75);

    return tinycolor.mostReadable(backgroundColor, [textColorDarken, textColorLighten], {
        includeFallbackColors: true,
        level: 'AAA',
        size: 'small',
        ...mostReadableArguments,
    });
}

export function getLockEventMessage(str: string) {
    switch (str) {
        case 'UNLOCKING_SUCCESSFUL':
            return 'Låst opp ✅';
        case 'UNLOCKING_FAILED':
            return 'Feil ved opplåsing ❌';
        case 'CYLINDER_SYNCHRONIZATION_VIA_OTA_AFTER_UNLOCK':
            return 'Synkronisering av sylinder etter opplåsing 🔄';
        default:
            return 'Ukjent hendelse';
    }
}

/**
 *
 * @param toDate
 * @param reservationDuration
 * @param reservationAvailability
 * @returns
 */
export function calculateBikeBoxAvailability(
    toDate: string,
    reservationDuration: number,
    reservationAvailability: {
        date: string;
        available: boolean;
    }[][],
    currentMonth: number
): BikeBoxAvailability {
    return reservationAvailability.map(monthAvailability => {
        return monthAvailability.map((dayAvailability, i) => {
            const availabilityDate = new Date(dayAvailability.date);
            const selected = isSameDay(availabilityDate, new Date(toDate));
            const calculatedFromDate = new Date(
                calculateReservationStartDate(
                    subDays(new Date(toDate), reservationDuration),
                    new Date(toDate),
                    reservationAvailability,
                    currentMonth
                )
            );

            const rangeSelected =
                isSameDay(availabilityDate, calculatedFromDate) ||
                (isAfter(availabilityDate, calculatedFromDate) && isBefore(availabilityDate, new Date(toDate)));
            return {
                date: dayAvailability.date,
                available: dayAvailability.available,
                selected,
                rangeSelected,
            };
        });
    });
}

export function calculateReservationStartDate(
    startDate: Date,
    endDate: Date,
    monthAvailability: { date: string; available: boolean }[][],
    currentMonthNumber: number
): string {
    let currentMonth = monthAvailability[currentMonthNumber];
    let fromDateAvailabilityIndex = findIndexOfDayOfMonth(startDate, currentMonth);
    // Go to previous or next month if date is not in current month
    if (fromDateAvailabilityIndex === -1 && isBefore(startDate, new Date(currentMonth[0].date))) {
        currentMonth = monthAvailability[currentMonthNumber - 1];
        fromDateAvailabilityIndex = findIndexOfDayOfMonth(startDate, currentMonth);
    } else if (
        fromDateAvailabilityIndex === -1 &&
        isAfter(startDate, new Date(currentMonth[currentMonth.length - 1].date))
    ) {
        currentMonth = monthAvailability[currentMonthNumber + 1];
        fromDateAvailabilityIndex = findIndexOfDayOfMonth(startDate, currentMonth);
    }
    if (fromDateAvailabilityIndex === -1) {
        return 'no date found';
    }

    const fromDateAvailability = currentMonth[fromDateAvailabilityIndex];

    let result = null;
    if (!fromDateAvailability.available) {
        let i = 0;
        let tempFromDate = { ...fromDateAvailability };
        while (i < 10 && !tempFromDate.available && !isSameDay(new Date(tempFromDate.date), new Date(endDate))) {
            fromDateAvailabilityIndex++;
            tempFromDate = currentMonth[fromDateAvailabilityIndex];
            i++;
        }
        result = tempFromDate.date;
    } else {
        result = startDate.toISOString();
    }

    // Check for reservation between start and end date
    const diffDays = differenceInDays(endDate, new Date(result));
    for (let i = 0; i < diffDays; i++) {
        let y = currentMonth[fromDateAvailabilityIndex + i];
        if (y === undefined && isBefore(new Date(result), endDate)) {
            currentMonth = monthAvailability[currentMonthNumber - 1];
            y = currentMonth[fromDateAvailabilityIndex];
        } else if (y === undefined && isAfter(new Date(result), endDate)) {
            currentMonth = monthAvailability[currentMonthNumber + 1];
            y = currentMonth[fromDateAvailabilityIndex];
        }
        if (!y.available) result = currentMonth[fromDateAvailabilityIndex + i + 1].date;
    }

    return result;
}

function findIndexOfDayOfMonth(date: Date, monthAvailability: { date: string; available: boolean }[]): number {
    return monthAvailability.findIndex(dayAvailability => isSameDay(new Date(dayAvailability.date), date));
}

export function getOrderErrorMessages(orders: Order[]): {
    id: string;
    label: string;
}[] {
    // Function for _flatMap-function to get all errorIds on object
    const getErrorIds = (error: { orderErrorIds: string[] }) => {
        // console.log(error);
        return [...error.orderErrorIds];
    };
    // Getting all error objects
    const errors = _map(orders, 'errors');
    // Getting all unique errorIds
    const errorIds = _uniq(_flatMap(errors, getErrorIds));
    // Getting errorNames for all errorIds
    const errorMessages = _map(errorIds, errorId => {
        return {
            id: errorId + '',
            label: getGenericOrderError('' + errorId).name,
        };
    });

    return errorMessages;
}

export function filterCriticalOrders(orders: Order[], filterState: FilterState) {
    let filtered: Order[] = [];

    // Sort orders
    if (filterState.sorting && typeof filterState?.sorting === 'string') {
        const split = filterState.sorting.split('-');
        const order = split[1] === 'asc' ? 'asc' : 'desc';

        switch (filterState.sorting) {
            case 'price-desc':
            case 'price-asc':
                // Sort by price
                filtered = _orderBy(orders, [split[0]], [order]);
                break;
            case 'date-desc':
            case 'date-asc':
                // Sort on date
                filtered = _sortBy(orders, order => {
                    return order.deliveryDate;
                });
                if (order === 'desc') filtered.reverse(); // _sortBy can only sort in one order.
                break;
        }
    }

    // Filter orders by employee
    if (filterState.employees !== 0) {
        filtered = _filter(filtered, ['employeeId', filterState.employees]);
    }

    // Filter orders by orderTemplate
    if (filterState.orderTemplates !== 0) {
        filtered = _filter(filtered, ['templateId', filterState.orderTemplates]);
    }

    // Filter orders by errorMessage
    if (filterState.errorMessages !== 0) {
        filtered = _filter(filtered, order => {
            return _intersection(order.errors?.orderErrorIds, [filterState.errorMessages.toString()]).length > 0;
        });
    }

    return filtered;
}
