import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import SubtleSpinner from '../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import { getArticleAttributes } from '../../../../state/ducks/inventory/ArticleAttributes';
import { useSelector } from '../../../../state/hooks';
import ArticleAttribute from '../../../../views/Inventory/Attributes/Attribute/ArticleAttribute';

const ArticleAttributeContainer = () => {
    const { attributeId } = useParams();
    const dispatch = useDispatch();

    const { articleAttributes } = useSelector(state => state.inventory);
    const currenctAttribute = articleAttributes.find(a => a.articleAttributeId.toString() === attributeId);

    useEffect(() => {
        if (!currenctAttribute) {
            dispatch(getArticleAttributes(null, null, null));
        }
    }, [dispatch, currenctAttribute]);

    if (currenctAttribute === undefined) return <SubtleSpinner text='Henter attributt...' />;
    else return <ArticleAttribute articleAttribute={currenctAttribute} />;
};

export default ArticleAttributeContainer;
