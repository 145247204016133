import { useState } from 'react';
import formatISO from 'date-fns/formatISO';

import { useSelector } from '../../../../../state/hooks';

import { calculateBlankCalendarDays } from '../../../../../utils/date';
import SubtleSpinner from '../../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import BookingCalendar from '../../../../../components/Extras/BookingCalendar/BookingCalendar';
import BookingCalendarCard from '../../../../../components/Cards/BookingCalendarCard/BookingCalendarCard';
import BookingCalendarMonthPicker from './BookingCalendarMonthPicker';
import { ServiceCapacity, SpecialCapacity, WeekdayCapacity } from '../../../../../utils/types';
import BookingCalendarModalContainer from '../BookingCalendarModalContainer';

type Props = {
    prioritizedCapacity: ServiceCapacity[][];
    nonPrioritizedCapacity: ServiceCapacity[][];
    activeStoreLocation: string;
    weekdayCapacity: WeekdayCapacity;
    calculatedWeekdayCapacities: WeekdayCapacity[];
    specialCapacity: SpecialCapacity[];
};

const BookingCalendarContainer = ({
    prioritizedCapacity,
    nonPrioritizedCapacity,
    activeStoreLocation,
    weekdayCapacity,
    calculatedWeekdayCapacities,
    specialCapacity,
}: Props) => {
    const { holidays } = useSelector(state => state.bookings);

    const [currMonth, setCurrMonth] = useState(0);
    const [open, setOpen] = useState(false);
    const [currentDay, setCurrentDay] = useState<CurrentDay>({
        date: null,
        capacity: null,
        specialCapacityId: null,
    });
    const [selectedStandardCapacity, setSelectedStandardCapacity] = useState(0);

    function handleChangeSelectedStandardCapacity(id: number) {
        setSelectedStandardCapacity(id);
    }

    function handleNextMonth() {
        setCurrMonth(prev => (prev < 12 ? prev + 1 : prev));
    }
    function handlePrevMonth() {
        setCurrMonth(prev => (prev > 0 ? prev - 1 : prev));
    }

    function handleOpenModal() {
        if (open) setCurrentDay({ date: null, capacity: null, specialCapacityId: null });
        setOpen(prev => !prev);
    }
    function handleCardClick(date: Date, capacity: number, specialCapacityId: string | null) {
        setCurrentDay({ date, capacity, specialCapacityId });
        setOpen(true);
    }

    let year = 0;
    let month = 0;
    let calendar = null;

    const serviceCapacity = selectedStandardCapacity === 0 ? nonPrioritizedCapacity : prioritizedCapacity;

    if (serviceCapacity && specialCapacity && weekdayCapacity && calculatedWeekdayCapacities.length > 0) {
        const firstDay = new Date(serviceCapacity[currMonth][0].date);
        year = firstDay.getFullYear();
        month = firstDay.getMonth();

        // const currCapacity =
        //     selectedStandardCapacity === 0 ? old_standardCapacity.standardCapacity : old_standardCapacity.prioritizedCapacity;
        const [beforeBlankNum, afterBlankNum] = calculateBlankCalendarDays(year, month);

        const days = serviceCapacity[currMonth].map((cap, i) => {
            const date = new Date(cap.date);
            const iso = formatISO(date, { representation: 'date' });
            //const special = old_specialCapacity?.[cap.date];
            let special: null | { id: string; count: number } = null;
            if (specialCapacity.length > 0) {
                const specialCap = specialCapacity.find(
                    sc =>
                        formatISO(new Date(sc.date), { representation: 'date' }) ===
                        formatISO(new Date(cap.date), { representation: 'date' })
                );
                if (specialCap) special = { id: specialCap.id, count: specialCap.count };
            }

            const isHoliday = holidays ? holidays[iso] : false;
            const weekday = calculatedWeekdayCapacities[selectedStandardCapacity][date.getDay()];

            return (
                <BookingCalendarCard
                    key={i + 'day'}
                    date={date}
                    capacity={special === null ? weekday : special.count}
                    available={cap.capacity ?? undefined}
                    isHoliday={!!isHoliday}
                    onClick={handleCardClick}
                    specialCapacityId={special?.id ?? null}
                />
            );
        });

        const blanks = [...Array(beforeBlankNum + afterBlankNum)].map((_, i) => (
            <BookingCalendarCard key={i + 'blanks'} />
        ));

        calendar = [...blanks.slice(0, beforeBlankNum), ...days, ...blanks.slice(beforeBlankNum)];
    }

    if (calendar) {
        return (
            <>
                <BookingCalendarMonthPicker
                    year={year}
                    month={month}
                    nextMonth={handleNextMonth}
                    previousMonth={handlePrevMonth}
                    handleSelectStandardCapacity={handleChangeSelectedStandardCapacity}
                    selectedStandardCapacity={selectedStandardCapacity}
                />
                <BookingCalendar>
                    <>{calendar}</>
                </BookingCalendar>
                <BookingCalendarModalContainer open={open} handleOpen={handleOpenModal} details={currentDay} />
            </>
        );
    }

    return <SubtleSpinner style={{ marginTop: 73 }} text='Henter booking data' />;
};

export interface CurrentDay {
    date: Date | null;
    capacity: number | null;
    specialCapacityId: string | null;
}

export default BookingCalendarContainer;
