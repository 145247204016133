import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormHelperText,
    TextField,
    Typography,
} from '@mui/material';
import { Controller, SubmitErrorHandler, SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { isAfter, parseISO } from 'date-fns';
import { useEffect } from 'react';

import Modal from '../Modal/Modal';
import Column from '../../Layouts/Column/Column';
import { PromotionalCodeDTO } from '../../../utils/types';

type Props = {
    openModal: boolean;
    toggleModal: () => void;
    onSubmit: (data: PromotionalCodeDTO) => void;
    isLoading: boolean;
    initialValues?: PromotionalCodeDTO;
};
const PromotionalCodeModal = ({ openModal, toggleModal: handleClose, onSubmit, isLoading, initialValues }: Props) => {
    const defaultValues = initialValues
        ? (Object.assign({}, initialValues) as Partial<PromotionalCodeDTO>)
        : ({ id: '' } as Partial<PromotionalCodeDTO>);

    // Dates are fetched as strings from the backend, so we need to parse them to Date objects
    if (initialValues?.startDate) defaultValues.startDate = parseISO(initialValues.startDate as unknown as string);
    if (initialValues?.endDate) defaultValues.endDate = parseISO(initialValues.endDate as unknown as string);

    const {
        control,
        setValue,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm<PromotionalCodeDTO>({
        defaultValues,
    });

    const [startDate, endDate] = useWatch({
        control,
        name: ['startDate', 'endDate'],
    });

    const onValidSubmit: SubmitHandler<PromotionalCodeDTO> = (data: PromotionalCodeDTO) => {
        const obj = Object.assign({}, data);
        if (!startDate) delete obj.startDate;
        if (!endDate) delete obj.endDate;
        onSubmit(obj);
    };

    const onInvalidSubmit: SubmitErrorHandler<PromotionalCodeDTO> = errors => {
        console.log('Invalid submit', errors);
    };

    useEffect(() => {
        if (startDate && endDate && isAfter(startDate, endDate)) {
            setError('startDate', { type: 'validate' });
            setError('endDate', { type: 'validate' });
        } else {
            clearErrors('startDate');
            clearErrors('endDate');
        }
    }, [startDate, endDate]);

    return (
        <Modal maxWidth='sm' openModal={openModal} toggleModal={handleClose}>
            <Box sx={{ padding: { md: 5, sm: 4 } }}>
                <Typography sx={{ marginBottom: 4 }} variant='h3'>
                    Lag en ny kampanjekode
                </Typography>
                <Column gap={2}>
                    <Controller
                        control={control}
                        name='name'
                        rules={{
                            required: true,
                            maxLength: 30,
                        }}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { isTouched, isDirty, error },
                            formState,
                        }) => (
                            <FormControl>
                                <TextField
                                    {...field}
                                    disabled={isLoading}
                                    label='Tekst kode'
                                    placeholder='Dette skriver kunden inn'
                                    variant='outlined'
                                    autoComplete='off'
                                    inputRef={ref}
                                />
                                {error?.type === 'required' && (
                                    <FormHelperText error>Tekst koden kan ikke være tom.</FormHelperText>
                                )}
                                {error?.type === 'maxLength' && (
                                    <FormHelperText error>Maks-lengde på fornavn er 30 tegn.</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Controller
                        control={control}
                        name='description'
                        rules={{
                            required: false,
                            maxLength: 100,
                        }}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { isTouched, isDirty, error },
                            formState,
                        }) => (
                            <FormControl>
                                <TextField
                                    {...field}
                                    disabled={isLoading}
                                    label='Beskrivelse (valgfritt)'
                                    placeholder='En kort beskrivelse av koden'
                                    variant='outlined'
                                    autoComplete='off'
                                    inputRef={ref}
                                    multiline
                                    minRows={3}
                                    maxRows={5}
                                />
                                {error?.type === 'maxLength' && (
                                    <FormHelperText error>Beskrivelsen kan maks være på 100 tegn.</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    {/* <Controller
                        control={control}
                        name='isActive'
                        defaultValue={true}
                        render={({
                            field: { ref, ...field },
                            fieldState: { isTouched, isDirty, error },
                            formState,
                        }) => (
                            <FormGroup>
                                <FormControlLabel control={<Switch defaultChecked />} label='Aktiv' />
                            </FormGroup>
                        )}
                    /> */}
                    <Column>
                        <Controller
                            control={control}
                            name='startDate'
                            rules={{
                                required: false,
                            }}
                            defaultValue={null}
                            render={({
                                field: { ref, ...field },
                                fieldState: { isTouched, isDirty, error },
                                formState,
                            }) => (
                                <FormControl>
                                    <DatePicker
                                        {...field}
                                        disabled={isLoading}
                                        label='Start-dato'
                                        inputRef={ref}
                                        format='dd/MM/yyyy'
                                        closeOnSelect
                                        views={['day']}
                                    />
                                    {error && (
                                        <FormHelperText error>
                                            Start-datoen kan ikke være senere enn slutt-datoen.
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                    </Column>
                    <Column>
                        <Controller
                            control={control}
                            name='endDate'
                            rules={{
                                required: false,
                            }}
                            defaultValue={null}
                            render={({
                                field: { ref, ...field },
                                fieldState: { isTouched, isDirty, error },
                                formState,
                            }) => (
                                <FormControl>
                                    <DatePicker
                                        {...field}
                                        disabled={isLoading}
                                        label='Slutt-dato (valgfritt)'
                                        inputRef={ref}
                                        format='dd/MM/yyyy'
                                        closeOnSelect
                                        views={['day']}
                                    />
                                    {error && (
                                        <FormHelperText error>
                                            Slutt-datoen kan ikke være tidligere enn start-datoen.
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            )}
                        />
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={isLoading}
                                        checked={endDate === null}
                                        onClick={() => setValue('endDate', null)}
                                    />
                                }
                                label='Ingen utløpsdato'
                            />
                        </FormGroup>
                    </Column>
                    <Controller
                        control={control}
                        name='customErrorMessage'
                        rules={{
                            required: false,
                            maxLength: 100,
                        }}
                        defaultValue={''}
                        render={({
                            field: { ref, ...field },
                            fieldState: { isTouched, isDirty, error },
                            formState,
                        }) => (
                            <FormControl>
                                <TextField
                                    {...field}
                                    disabled={isLoading}
                                    label='Feilmelding for kunde (valgfritt)'
                                    placeholder='"Koden er brukt opp"'
                                    variant='outlined'
                                    autoComplete='off'
                                    inputRef={ref}
                                />
                                <FormHelperText>Dette vises til kunden hvis koden ikke er gyldig.</FormHelperText>
                                {error?.type === 'maxLength' && (
                                    <FormHelperText error>Beskrivelsen kan maks være på 100 tegn.</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                    <Box mt={3}>
                        <Button
                            disabled={isLoading || Object.keys(errors).length > 0}
                            variant='contained'
                            fullWidth
                            sx={{ mb: 2 }}
                            onClick={handleSubmit(onValidSubmit, onInvalidSubmit)}
                        >
                            Lag kampanjekode
                        </Button>
                        <Button disabled={isLoading} fullWidth onClick={handleClose}>
                            Avbryt
                        </Button>
                    </Box>
                </Column>
            </Box>
        </Modal>
    );
};

export default PromotionalCodeModal;
