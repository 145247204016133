import { Box, Button, Typography } from '@mui/material';

import { useSelector } from '../../../state/hooks';
import PromotionalCodesContainer from '../../../containers/PageSpecific/Bookings/PromotionalCodes/PromotionalCodesContainer';
import SubtleSpinner from '../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import { Plus } from 'react-feather';
import PromotionalCodeModal from '../../../components/Modals/PromotionalCodeModal/PromotionalCodeModal';
import { useEffect, useState } from 'react';
import { useCreatePromotionalCodeMutation } from '../../../services/PromotionalCodes';
import { PromotionalCodeDTO } from '../../../utils/types';

const PromotionalCodes = () => {
    const activeStoreLocation = useSelector(state => state.system.activeStoreLocation);
    const [openModal, setOpenModal] = useState(false);

    const handleToggleModal = () => setOpenModal(!openModal);

    const [createPromoCode, { isLoading: isCreating, isSuccess: createSuccessful, data }] =
        useCreatePromotionalCodeMutation();

    useEffect(() => {
        if (createSuccessful && data) {
            setOpenModal(false);
        }
    }, [createSuccessful, data]);

    if (activeStoreLocation === null) return <SubtleSpinner />;

    const handleCreatePromoCode = (data: PromotionalCodeDTO) => {
        createPromoCode({ storeName: activeStoreLocation, data });
    };

    return (
        <Box sx={{ px: { md: 5, xs: 4 } }}>
            <Typography variant='h2'>Kampanjekoder</Typography>

            {activeStoreLocation !== null ? (
                <PromotionalCodesContainer storeName={activeStoreLocation} />
            ) : (
                <SubtleSpinner />
            )}
            <Button onClick={handleToggleModal} variant='contained' startIcon={<Plus />} sx={{ mt: 3 }}>
                Ny kampanjekode
            </Button>
            {activeStoreLocation !== null && (
                <PromotionalCodeModal
                    openModal={openModal}
                    toggleModal={handleToggleModal}
                    onSubmit={handleCreatePromoCode}
                    isLoading={isCreating && !createSuccessful}
                />
            )}
        </Box>
    );
};

export default PromotionalCodes;
