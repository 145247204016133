import { PromotionalCodeDTO } from '../utils/types';
import { api } from './api';

export const promotionalCodesApi = api.injectEndpoints({
    endpoints: builder => ({
        getPromotionalCodes: builder.query<PromotionalCodeDTO[], { storeName: string }>({
            query: ({ storeName }) => ({
                url: `/servicePromotionalCodes`,
                params: {
                    storeName,
                },
                method: 'GET',
            }),
            providesTags: ['PromotionalCodes'],
            transformResponse: (response: { result: PromotionalCodeDTO[]; error: any }) => response.result,
        }),
        getPromotionalCode: builder.query<PromotionalCodeDTO, { storeName: string; promoCodeId: string }>({
            query: ({ storeName, promoCodeId }) => ({
                url: `/servicePromotionalCodes/${promoCodeId}`,
                params: {
                    storeName,
                },
                method: 'GET',
            }),
            providesTags: ['PromotionalCodes'],
            transformResponse: (response: { result: PromotionalCodeDTO; error: any }) => response.result,
        }),
        createPromotionalCode: builder.mutation<PromotionalCodeDTO, { storeName: string; data: PromotionalCodeDTO }>({
            query: ({ storeName, data }) => ({
                url: `/servicePromotionalCodes`,
                params: {
                    storeName,
                },
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['PromotionalCodes'],
            transformResponse: (response: { result: PromotionalCodeDTO; error: any }) => response.result,
        }),
        updatePromotionalCode: builder.mutation<PromotionalCodeDTO, { storeName: string; data: PromotionalCodeDTO }>({
            query: ({ storeName, data }) => ({
                url: `/servicePromotionalCodes/${data.id}`,
                params: {
                    storeName,
                },
                method: 'PUT',
                body: data,
            }),
            invalidatesTags: ['PromotionalCodes'],
            transformResponse: (response: { result: PromotionalCodeDTO; error: any }) => response.result,
        }),
        deletePromotionalCode: builder.mutation<PromotionalCodeDTO, { storeName: string; promoCodeId: string }>({
            query: ({ storeName, promoCodeId }) => ({
                url: `/servicePromotionalCodes/${promoCodeId}`,
                params: {
                    storeName,
                },
                method: 'DELETE',
            }),
            invalidatesTags: ['PromotionalCodes'],
            // transformResponse: (response: { result: PromotionalCodeDTO; error: any }) => response.result,
        }),
    }),
});

export const {
    useGetPromotionalCodesQuery,
    useGetPromotionalCodeQuery,
    useCreatePromotionalCodeMutation,
    useUpdatePromotionalCodeMutation,
    useDeletePromotionalCodeMutation,
} = promotionalCodesApi;
