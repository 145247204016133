import { Box, Button, Chip, CircularProgress, Collapse, Grid, Typography } from '@mui/material';
import { useState } from 'react';

import ExpandButton from '../../../../components/Buttons/ExpandButton/ExpandButton';
import SubtleSpinner from '../../../../components/Indicators/SubtleSpinner/SubtleSpinner';
import Axis from '../../../../components/Layouts/Axis/Axis';
import PromptModal from '../../../../components/Modals/PromptModal/PromptModal';
import BikeBoxReservationsTable from '../../../../components/Tables/BikeBoxReservationsTable/BikeBoxReservationsTable';
import SmallLabel from '../../../../components/Typography/SmallLabel/SmallLabel';
import BikeBoxCalendarContainer from '../../../../containers/PageSpecific/Bookings/BikeBoxes/BikeBoxCalendarContainer/BikeBoxCalendarContainer';
import LockEventHistoryTableContainer from '../../../../containers/PageSpecific/Bookings/BikeBoxes/LockEventHistoryTableContainer/LockEventHistoryTableContainer';
import { useGetBikeBoxByIdQuery } from '../../../../services/BikeBoxes';
import { useDeleteBikeBoxReservationMutation } from '../../../../services/BikeBoxReservation';
import { formatDateToString } from '../../../../utils/date';
import { BikeBoxReservation } from '../../../../utils/types';
import NestedResourceNavigationLayout from '../../../../components/Layouts/NestedResourceNavigationLayout/NestedResourceNavigationLayout';
import { capitalizeText } from '../../../../utils/format';

export type Props = {
    bikeBoxId: string;
    activeStoreLocation: string;
};

const BikeBox = ({ bikeBoxId, activeStoreLocation }: Props) => {
    const {
        data: bikeBox,
        isLoading,
        isSuccess,
    } = useGetBikeBoxByIdQuery({ id: bikeBoxId, storeName: activeStoreLocation });
    const [showBikeBoxInfo, setShowBikeBoxInfo] = useState(false);

    const [deleteReservation, { isLoading: isDeleting }] = useDeleteBikeBoxReservationMutation();

    const [showDeleteModal, setShowDeleteModal] = useState(false);

    if (isLoading || !isSuccess || !bikeBox) return <SubtleSpinner />;

    const activeReservation: BikeBoxReservation | null = bikeBox.activeReservation;
    const isActive = activeReservation !== null;

    const handleDeleteReservation = (id: number) => {
        deleteReservation({ bikeBoxId: parseInt(bikeBoxId), bikeBoxReservationId: id, storeName: activeStoreLocation });
    };

    return (
        <NestedResourceNavigationLayout currentPathname={bikeBox.name} parentPath='Sykkelbokser'>
            <>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 4 }}>
                    <Typography sx={{ mr: 1 }} variant='h2'>
                        {bikeBox.name}
                    </Typography>
                    <ExpandButton
                        isExpanded={showBikeBoxInfo}
                        handleClick={() => setShowBikeBoxInfo(!showBikeBoxInfo)}
                    />
                    <Chip
                        sx={{ ml: 2 }}
                        label={isActive ? 'Opptatt' : 'Ledig'}
                        color={isActive ? 'error' : 'success'}
                    />
                </Box>
                <Collapse in={showBikeBoxInfo}>
                    <Box sx={{ mb: 4 }}>
                        <Axis isResponsive gap={2}>
                            <Box>
                                <SmallLabel text={'Sykkelboks ID'} />
                                <Typography>{bikeBox.id}</Typography>
                            </Box>
                        </Axis>
                    </Box>
                </Collapse>
                {isActive && (
                    <Box sx={{ mb: 4 }}>
                        <Typography variant='h3' sx={{ mb: 2 }}>
                            Kunde
                        </Typography>
                        <Grid container wrap='wrap' gap={2}>
                            <Grid item>
                                <Box>
                                    <SmallLabel text={'Kundenavn'} />
                                    <Typography>{capitalizeText(activeReservation.customerName)}</Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box>
                                    <SmallLabel text={'Mobilnummer'} />
                                    <Typography>{activeReservation.phoneNumber}</Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box>
                                    <SmallLabel text={'Fra-dato'} />
                                    <Typography>
                                        {formatDateToString(
                                            new Date(activeReservation?.fromDate) ?? null,
                                            'eeee do MMMM yyyy'
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item>
                                <Box>
                                    <SmallLabel text={'Til-dato'} />
                                    <Typography>
                                        {formatDateToString(
                                            new Date(activeReservation?.toDate) ?? null,
                                            'eeee do MMMM yyyy'
                                        )}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Box sx={{ mt: 2 }}>
                            <Button
                                variant='contained'
                                sx={{ mr: 2 }}
                                onClick={() => setShowDeleteModal(!showDeleteModal)}
                            >
                                Frigjør boks
                            </Button>
                            <Button variant='outlined'>Endre dato</Button>
                        </Box>
                        <PromptModal
                            openModal={showDeleteModal}
                            toggleModal={() => setShowDeleteModal(!showDeleteModal)}
                            title='Bekreft sletting av reservasjon'
                            description='Kunden mister umiddelbart tilgang til sykkelboksen.'
                            leftButton={
                                <Button disabled={isDeleting} onClick={() => setShowDeleteModal(!showDeleteModal)}>
                                    Avbryt
                                </Button>
                            }
                            rightButton={
                                <Button
                                    startIcon={isDeleting && <CircularProgress size={16} />}
                                    disabled={isDeleting}
                                    variant='contained'
                                    color='error'
                                    onClick={() => handleDeleteReservation(activeReservation.authorizationId)}
                                >
                                    {isDeleting ? 'Frigjør boksen...' : 'Frigjør boks'}
                                </Button>
                            }
                            maxWidth='sm'
                        />
                    </Box>
                )}
                <Box sx={{ mb: 4 }}>
                    <Typography variant='h3' sx={{ mb: 2 }}>
                        Kalender for ledighet
                    </Typography>

                    <BikeBoxCalendarContainer bikeBoxId={bikeBoxId} activeStoreLocation={activeStoreLocation} />
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Typography variant='h3' sx={{ mb: 2 }}>
                        Fremtidige reservasjoner
                    </Typography>
                    {bikeBox.reservations === null ? (
                        <SubtleSpinner />
                    ) : (
                        <BikeBoxReservationsTable
                            reservations={bikeBox.reservations}
                            handleDeleteReservation={handleDeleteReservation}
                        />
                    )}
                </Box>
                <Box sx={{ mb: 4 }}>
                    <Typography variant='h3' sx={{ mb: 2 }}>
                        Låsehistorikk
                    </Typography>
                    <LockEventHistoryTableContainer bikeBoxId={bikeBoxId} />
                </Box>
            </>
        </NestedResourceNavigationLayout>
    );
};

export default BikeBox;
